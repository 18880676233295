const NEWEST_IOS_VERSION = 17;
const NEWEST_ANDROID_VERSION = 14;

export const isNewestMobileOsVersion = (os: {name: string; version: string}) => {
    // Shaves of any minor or bug versions
    const parsedVersion = parseInt(os.version, 10);

    switch (os.name) {
        case 'iOS':
            return parsedVersion >= NEWEST_IOS_VERSION;
        case 'Android':
            return parsedVersion >= NEWEST_ANDROID_VERSION;
        default:
            return false;
    }
};
