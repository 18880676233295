import {generatePath as baseGeneratePath} from 'react-router-dom';
/**
 * We allow an import directly from react-router here because:
 * 1. It's only exported by react-router
 * 2. It's only a type, so it will be transpiled away
 */
// eslint-disable-next-line no-restricted-imports
import {type ExtractRouteParams} from 'react-router';

import {hasValue, omit} from 'web-app/util/typescript';
import {buildUrlWithQueryParameters} from 'web-app/util/url';

// Used to attribute Sentry errors in routes to a specific crew
export type Crew = 'foundation' | 'finance' | 'growth' | 'payments' | 'ptp' | 'stat';

export interface Route {
    emberIdent: string;
    path: string;
    subRoutes?: {[emberIdent: string]: Route};
    // The crew who owns the route
    crew: Crew;
}

export type RouteConfigType = {[routeName: string]: Route};

/**
 * Type system for converting to RouteConfig's paths into a union of string literals for type safe routes and auto completion
 *
 * The types GenericRoute, and GenericRouteMap is a generic representation of the shape of RouteConfig.
 */
// A route is either a leaf with just a path, or it can contain subRoutes
type GenericRoute = GenericRouteLeaf | GenericRouteWithRoutes;
// A route is a leaf if it doesn't contains any subRoutes
type GenericRouteLeaf = {path: string};
// A route can contains subRoutes which contains cyclical references
type GenericRouteWithRoutes = GenericRouteLeaf & {subRoutes: GenericRouteMap};

// Routes are organized using keys
type GenericRouteMap = {[x: string]: GenericRoute};

type ValueOf<T> = T[keyof T];
type Routes<T extends GenericRouteMap> = ValueOf<T>;

/**
 * Without setting a maximum recursion depth for the type, Typescript will fail compiling with the error
 * "Type instantiation is excessively deep and possibly infinite".
 *
 * By using an array that's offset by 1 (MaxDepth[4] = 3, MaxDepth[0] = never), we can use it as a counter
 * by passing the value into the next recursion and bail out if we ever hit `never`. Typescript can statically
 * analyze the maximum recursion depth with this approach which solves the issue of infinite recursion depth.
 */
type MaxDepth = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

/**
 * Produces a string literal union that contains every traversable path in a GenericRouteMap shape.
 */
type RecursePath<P extends Routes<GenericRouteMap>, D extends MaxDepth[number] = 5> = D extends [never]
    ? // Bail out if we hit maximum recursion depth
      never
    : P extends GenericRouteWithRoutes
    ? // Recurse if the path contains subRoutes
      P['path'] | `${P['path']}${RecursePath<Routes<P['subRoutes']>, MaxDepth[D]>}`
    : // Return path if route is a leaf
      P['path'];

// Converts a route map into a union of string literals
type PathUnion<T extends GenericRouteMap> = RecursePath<Routes<T>>;

// Converts the RouteConfig const into a union of string literals
export type RouteConfigPath = PathUnion<typeof RouteConfig>;

// Optional query parameters for path generation
type QueryParams = {queryParams?: {[key: string]: string}};

/**
 * Arguments for the path generator functions.
 * This extracts params based on the path, i.e. PathParams<`foo/:bar/baz`> => {bar: string}.
 * If a path contains no params then valid arguments are either just a path, or path and query params.
 * If path does contain params, then arguments needs both path and params.
 */
export type PathGenerationArgs<T extends PathUnion<GenericRouteMap>> = keyof ExtractRouteParams<T> extends never
    ? // If the path doesn't contain any params, arguments should just be path with optional query params
      [T] | [T, QueryParams]
    : // Require second argument if path contains params
      [T, ExtractRouteParams<T>] | [T, ExtractRouteParams<T> & QueryParams];

/**
 * Helper function for picking query parameters out of params while still maintaining type safety.
 */
export const extractParams = <T extends PathUnion<GenericRouteMap>>([path, params]: PathGenerationArgs<T>) => {
    // Extract query params if they exist
    const queryParams = params && 'queryParams' in params ? params.queryParams : undefined;

    // Omit queryParams from params if they exist
    const routeParams = params && 'queryParams' in params ? omit(params, 'queryParams') : params;

    // Coerce Typescript by helping with tuple type
    return [path, routeParams, queryParams] as [T, ExtractRouteParams<T>, QueryParams['queryParams']];
};

/**
 * Function that accepts a parametric path and its parameters, and returns the computed path
 * prefixed with a hash
 *
 * @param args [Path: string, Params?: object]
 * @returns `string` hashed path
 * @example
 * ```ts
 * generateHash('foo/:bar/wiz/:biz, {bar: 'a', biz: 'b'}); // "#/foo/a/wiz/b"
 * ```
 */
export const generatePath = <T extends PathUnion<GenericRouteMap>>(args: PathGenerationArgs<T>) => {
    const [path, params, queryParams] = extractParams(args);

    const hash = baseGeneratePath(path, params);

    if (hasValue(queryParams)) {
        return buildUrlWithQueryParameters(hash, queryParams);
    }
    return hash;
};

// Use this to directly generate a hash for any route that exists in the config
export const generateHash = <T extends RouteConfigPath>(...args: PathGenerationArgs<T>) => `#${generatePath(args)}`;

// Use this to directly generate a path for any route that exists in the config
export const generateReactRouterPath = <T extends RouteConfigPath>(...args: PathGenerationArgs<T>) =>
    `${generatePath(args)}`;

// Convenience function to easily assign paths
export const withPath = <T extends RouteConfigPath>(path: T) => path;

/**
 * While phasing out the Ember Router we have to keep this common config that both routers refer to.
 * This is due to Ember not letting any traffic through to routes it doesn't know about
 */
const RouteConfig = {
    welcome: {emberIdent: 'welcome', path: '/welcome/:token', crew: 'foundation'},
    invitation: {
        emberIdent: 'invitation',
        path: '/invitation',
        subRoutes: {
            challenge: {
                emberIdent: 'challenge',
                path: '/:roleInvitationId',
                crew: 'foundation',
            },
            accepted: {
                emberIdent: 'accepted',
                path: '/accepted',
                crew: 'foundation',
            },
            denied: {
                emberIdent: 'denied',
                path: '/denied',
                crew: 'foundation',
            },
        },
        crew: 'foundation',
    },
    famlyLiveJoinMeeting: {emberIdent: 'famlyLive/joinMeeting', path: '/famlyLive/joinMeeting', crew: 'ptp'},
    famlyLiveMeetingLeft: {emberIdent: 'famlyLiveMeetingLeft', path: '/famlyLiveMeetingLeft', crew: 'ptp'},
    forgotPassword: {emberIdent: 'forgotPassword', path: '/forgotPassword', crew: 'foundation'},
    signup: {emberIdent: 'signup', path: '/signup', crew: 'growth'},
    debugger: {emberIdent: 'debugger', path: '/debugger', crew: 'foundation'},
    icons: {emberIdent: 'icons', path: '/icons', crew: 'foundation'},
    inquiry: {emberIdent: 'inquiry', path: '/inquiry', crew: 'stat'},
    contextChallenge: {emberIdent: 'contextChallenge', path: '/contextChallenge', crew: 'foundation'},
    testLogin: {emberIdent: 'testLogin', path: '/testLogin', crew: 'foundation'},
    errorTest: {emberIdent: 'errorTest', path: '/errorTest', crew: 'foundation'},
    errorLog: {emberIdent: 'errorLog', path: '/errorLog', crew: 'foundation'},
    connectTokenSent: {emberIdent: 'connectTokenSent', path: '/connectTokenSent', crew: 'foundation'},
    resetPassword: {emberIdent: 'resetPassword', path: '/resetPassword', crew: 'foundation'},
    oneTimeLogin: {emberIdent: 'one-time-login', path: '/one-time-login', crew: 'foundation'},
    confirmEmail: {emberIdent: 'confirmEmail', path: '/confirmEmail', crew: 'foundation'},
    newAccount: {emberIdent: 'newAccount', path: '/newAccount', crew: 'foundation'},
    notFound: {emberIdent: 'notFound', path: '/notFound', crew: 'foundation'},
    customInquiryForm: {
        emberIdent: 'customInquiryForm',
        path: '/customInquiryForm/:formId/to/:siteSetId',
        crew: 'stat',
        subRoutes: {
            // Private route
            preview: {
                emberIdent: 'preview',
                path: '/preview',
                crew: 'stat',
            },
            // Public route
            submit: {
                emberIdent: 'submit',
                path: '/submit',
                crew: 'stat',
            },
        },
    },
    tooManyFailedLoginAttempts: {
        emberIdent: 'tooManyFailedLoginAttempts',
        path: '/tooManyFailedLoginAttempts',
        crew: 'foundation',
    },
    contract: {emberIdent: 'contract', path: '/contract', crew: 'foundation'},
    unavailable: {emberIdent: 'unavailable', path: '/unavailable', crew: 'foundation'},
    login: {emberIdent: 'login', path: '/login', crew: 'foundation'},
    offer: {emberIdent: 'offer', path: '/offer/:opportunityId', crew: 'growth'},
    emailVerification: {
        emberIdent: 'emailVerification',
        path: '/emailVerification/:verificationCode/to/:siteSetId',
        crew: 'foundation',
    },
    newSiteRequest: {emberIdent: 'newSiteRequest', path: '/newSiteRequest', crew: 'growth'},

    // Open id
    openid: {
        emberIdent: 'openid',
        path: '/openid',
        crew: 'foundation',
        subRoutes: {
            // Route used as callback when authenticating with an OIDC provider
            redirect: {
                emberIdent: 'redirect',
                path: '/redirect',
                crew: 'foundation',
            },
            // Used for connecting a Famly account with an OIDC account
            connect: {
                emberIdent: 'connect',
                path: '/connect',
                crew: 'foundation',
            },
            // Route used as callback when connecting your a Famly account with an OIDC account
            connectCallback: {
                emberIdent: 'connectCallback',
                path: '/connectCallback',
                crew: 'foundation',
            },
            // Used for authenticating with a token - used for e.g. changing pin codes (for OIDC users)
            tokenCallback: {
                emberIdent: 'tokenCallback',
                path: '/tokenCallback',
                crew: 'foundation',
            },
        },
    },

    // Component test route
    components: {
        emberIdent: 'components',
        path: '/components',
        crew: 'foundation',
        subRoutes: {
            timepicker: {
                emberIdent: 'timepicker',
                path: '/timepicker',
                crew: 'foundation',
            },
            intervalpicker: {
                emberIdent: 'intervalpicker',
                path: '/intervalpicker',
                crew: 'foundation',
            },
            rangepicker: {
                emberIdent: 'rangepicker',
                path: '/rangepicker',
                crew: 'foundation',
            },
            modernFamlyIntegration: {
                emberIdent: 'modernFamlyIntegration',
                path: '/modernFamlyIntegration',
                crew: 'foundation',
            },
            datePickerInModals: {
                emberIdent: 'datePickerInModals',
                path: '/datePickerInModals',
                crew: 'foundation',
            },
        },
    },
    /*
    |---------------------------------------------------------------------------------
    | Account routes
    |---------------------------------------------------------------------------------
    */
    account: {
        emberIdent: 'account',
        path: '/account',
        crew: 'foundation',
        subRoutes: {
            event: {emberIdent: 'event', path: '/event/:eventId', crew: 'ptp'},
            newWeekPlan: {emberIdent: 'newWeekPlan', path: '/newWeekPlan', crew: 'ptp'},
            calendar: {
                emberIdent: 'calendar',
                path: '/calendar',
                crew: 'ptp',
                subRoutes: {
                    me: {emberIdent: 'me', path: '/me', crew: 'ptp'},
                },
            },
            activities: {
                emberIdent: 'activities',
                path: '/activities',
                crew: 'ptp',
                subRoutes: {
                    library: {emberIdent: 'library', path: '/library/:libraryId', crew: 'ptp'},
                    activity: {emberIdent: 'activity', path: '/activity/:id', crew: 'ptp'},
                },
            },
            inbox: {emberIdent: 'inbox', path: '/inbox', crew: 'ptp'},
            dataExports: {emberIdent: 'dataExports', path: '/dataExports', crew: 'foundation'},
            moreMenu: {emberIdent: 'moreMenu', path: '/moreMenu', crew: 'foundation'},
            navigationV2MobileMenu: {
                emberIdent: 'navigationV2MobileMenu',
                path: '/navigationV2MobileMenu',
                crew: 'foundation',
                subRoutes: {
                    navigationGroup: {
                        emberIdent: 'navigationGroup',
                        path: '/navigationGroup/:groupId',
                        crew: 'foundation',
                    },
                },
            },
            billingOverview: {emberIdent: 'billingOverview', path: '/billingOverview', crew: 'finance'},
            post: {
                emberIdent: 'post',
                path: '/post/:postId',
                crew: 'ptp',
                subRoutes: {
                    comments: {emberIdent: 'comments', path: '/comments', crew: 'ptp'},
                },
            },
            // Legacy route for keeping links in notification emails working
            posts: {
                emberIdent: 'posts',
                path: '/posts/:postId',
                crew: 'ptp',
                subRoutes: {
                    comments: {emberIdent: 'comments', path: '/comments', crew: 'ptp'},
                },
            },
            feed: {
                emberIdent: 'feed',
                path: '/feed',
                crew: 'ptp',
                subRoutes: {
                    me: {emberIdent: 'me', path: '/me', crew: 'ptp'},
                    bookmarks: {emberIdent: 'bookmarks', path: '/bookmarks', crew: 'ptp'},
                },
            },
            // Lesson plans parent route
            lessonPlans: {emberIdent: 'lessonPlans', path: '/lessonPlans', crew: 'ptp'},
            notifications: {emberIdent: 'notifications', path: '/notifications', crew: 'foundation'},
            balances: {emberIdent: 'balances', path: '/balances/:loginId', crew: 'finance'},
            previousChildren: {emberIdent: 'previousChildren', path: '/previousChildren', crew: 'foundation'},
            childVacationReply: {
                emberIdent: 'childVacationReply',
                path: '/childVacationReply/:vacationId',
                crew: 'stat',
            },
            openid: {
                emberIdent: 'openid',
                path: '/openid',
                crew: 'foundation',
                subRoutes: {connect: {emberIdent: 'connect', path: '/connect', crew: 'foundation'}},
            },
            childProfile: {
                emberIdent: 'childProfile',
                path: '/childProfile/:childId',
                crew: 'foundation',
                subRoutes: {
                    activity: {emberIdent: 'activity', path: '/activity', crew: 'ptp'},
                    about: {emberIdent: 'about', path: '/about', crew: 'foundation'},
                    sleep: {emberIdent: 'sleep', path: '/sleep', crew: 'ptp'},
                    institutionInfo: {emberIdent: 'institutionInfo', path: '/institutionInfo', crew: 'foundation'},
                    leave: {emberIdent: 'leave', path: '/leave', crew: 'stat'},
                    photos: {emberIdent: 'photos', path: '/photos', crew: 'foundation'},
                    childProfilePlans: {emberIdent: 'childProfilePlans', path: '/childProfilePlans', crew: 'finance'}, // this route just redirects to /plansAndInvoices
                    childProfileBilling: {
                        emberIdent: 'childProfileBilling',
                        path: '/childProfileBilling',
                        crew: 'finance',
                    }, // this route just redirects to /plansAndInvoices
                    childProfileRelations: {
                        emberIdent: 'childProfileRelations',
                        path: '/childProfileRelations',
                        crew: 'foundation',
                    },
                    plansAndInvoices: {emberIdent: 'plansAndInvoices', path: '/plansAndInvoices', crew: 'finance'},
                    childProfileNotes: {
                        emberIdent: 'childProfileNotes',
                        path: '/childProfileNotes',
                        crew: 'ptp',
                        subRoutes: {
                            index: {
                                emberIdent: 'index',
                                path: '/',
                                crew: 'ptp',
                            },
                            note: {
                                emberIdent: 'note',
                                path: '/:noteId',
                                crew: 'ptp',
                            },
                        },
                    },
                    childProfileLearningJourney: {
                        emberIdent: 'childProfileLearningJourney',
                        path: '/childProfileLearningJourney',
                        crew: 'ptp',
                        subRoutes: {
                            index: {
                                emberIdent: 'index',
                                path: '/',
                                crew: 'ptp',
                            },
                            observation: {
                                emberIdent: 'observation',
                                path: '/:observationId',
                                crew: 'ptp',
                            },
                        },
                    },
                    childProfileLearningJourneyDetails: {
                        emberIdent: 'childProfileLearningJourneyDetails',
                        path: '/childProfileLearningJourneyDetails',
                        crew: 'ptp',
                    },
                    childProfileAccidentIncidentReports: {
                        emberIdent: 'childProfileAccidentIncidentReports',
                        path: '/childProfileAccidentIncidentReports',
                        crew: 'ptp',
                    },
                    safeguarding: {
                        emberIdent: 'safeguarding',
                        path: '/safeguarding',
                        crew: 'ptp',
                    },
                    family: {
                        emberIdent: 'family',
                        path: '/family',
                        crew: 'foundation',
                    },
                    printPreview: {
                        emberIdent: 'printPreview',
                        path: '/printPreview',
                        crew: 'growth',
                    },
                },
            },
            settings: {emberIdent: 'settings', path: '/settings', crew: 'foundation'},
            accountSettings: {
                emberIdent: 'accountSettings',
                path: '/accountSettings',
                crew: 'foundation',
                subRoutes: {
                    accountDetails: {emberIdent: 'accountDetails', path: '/accountDetails', crew: 'foundation'},
                    accountNotifications: {
                        emberIdent: 'accountNotifications',
                        path: '/accountNotifications',
                        crew: 'foundation',
                    },
                    accountPassword: {emberIdent: 'accountPassword', path: '/accountPassword', crew: 'foundation'},
                    accountPincode: {emberIdent: 'accountPincode', path: '/accountPincode', crew: 'foundation'},
                    paymentDetails: {emberIdent: 'paymentDetails', path: '/paymentDetails', crew: 'finance'},
                },
            },
            subscription: {emberIdent: 'subscription', path: '/subscription', crew: 'finance'},
            /*
                Since we added Famly Store to the institution routes, the link in the old nav was broken.
                This was due to different way of handling routes and nav links in the old and new nav.
                The solution is to keep both routes (at least for some time) until we are sure, nobody is on the old nav.
            */
            famlyStore: {
                emberIdent: 'famlyStore',
                path: '/famlyStore',
                subRoutes: {
                    packagePurchase: {
                        emberIdent: 'packagePurchase',
                        path: '/packagePurchase/:institutionId/:initialPackageId',
                        crew: 'growth',
                    },
                },
                crew: 'growth',
            },
            /*
                Sales to CS flow onboarding tool - customer led self-sign-up after accepting agreed contract (terms of deal)
                Read more about SCS flow here: https://www.notion.so/famly/Sales-to-CS-handover-681a34e59acc47d69f9573a32a41b80e
            */
            scsFlowOnboarding: {
                emberIdent: 'scsFlowOnboarding',
                path: '/scsFlowOnboarding',
                crew: 'growth',
            },

            reports: {
                emberIdent: 'reports',
                path: '/reports',
                crew: 'foundation',
                subRoutes: {
                    lists: {
                        emberIdent: 'lists',
                        path: '/lists',
                        crew: 'foundation',
                        subRoutes: {listFilter: {emberIdent: 'list-filter', path: '/list-filter', crew: 'foundation'}},
                    },
                    famlyInsights: {
                        emberIdent: 'famlyInsights',
                        path: '/famlyInsights',
                        crew: 'foundation',
                    },
                    category: {
                        emberIdent: 'category',
                        path: '/categories/:categoryId',
                        crew: 'foundation',
                        subRoutes: {
                            report: {
                                emberIdent: 'report',
                                path: '/reports/:reportId',
                                crew: 'foundation',
                            },
                        },
                    },
                    report: {
                        emberIdent: 'report',
                        path: '/report/:reportId',
                        crew: 'foundation',
                    },
                    new: {
                        emberIdent: 'new',
                        path: '/new',
                        crew: 'foundation',
                        subRoutes: {
                            fromDataset: {
                                emberIdent: 'fromDataset',
                                path: '/fromDataset/:datasetId',
                                crew: 'foundation',
                            },
                            fromReportTemplate: {
                                emberIdent: 'fromReportTemplate',
                                path: '/fromReportTemplate/:reportTemplateId',
                                crew: 'foundation',
                            },
                        },
                    },
                },
            },
            financeReports: {
                crew: 'foundation',
                emberIdent: 'financeReports',
                path: '/financeReports',
                subRoutes: {
                    report: {
                        crew: 'foundation',
                        emberIdent: 'report',
                        path: '/:reportId',
                    },
                },
            },
            learningReports: {
                crew: 'foundation',
                emberIdent: 'learningReports',
                path: '/learningReports',
                subRoutes: {
                    report: {
                        crew: 'foundation',
                        emberIdent: 'report',
                        path: '/:reportId',
                    },
                },
            },
            attendanceReports: {
                crew: 'foundation',
                emberIdent: 'attendanceReports',
                path: '/attendanceReports',
                subRoutes: {
                    report: {
                        crew: 'foundation',
                        emberIdent: 'report',
                        path: '/:reportId',
                    },
                },
            },
            /*
            |---------------------------------------------------------------------------------
            | Institution routes
            |---------------------------------------------------------------------------------
            */
            institution: {
                emberIdent: 'institution',
                path: '/institution/:institutionId',
                crew: 'foundation',
                subRoutes: {
                    home: {
                        crew: 'foundation',
                        emberIdent: 'home',
                        path: '/home',
                    },
                    homeCustomization: {
                        crew: 'foundation',
                        emberIdent: 'customization',
                        path: '/customization',
                    },
                    activities: {
                        emberIdent: 'activities',
                        path: '/activities',
                        crew: 'ptp',
                        subRoutes: {
                            library: {emberIdent: 'library', path: '/library/:libraryId', crew: 'ptp'},
                            activity: {emberIdent: 'activity', path: '/activity/:id', crew: 'ptp'},
                        },
                    },
                    feed: {
                        emberIdent: 'feed',
                        path: '/feed',
                        crew: 'ptp',
                    },
                    calendar: {
                        emberIdent: 'calendar',
                        path: '/calendar',
                        crew: 'stat',
                    },
                    parentalPermissions: {
                        emberIdent: 'permissions',
                        path: '/permissions',
                        crew: 'ptp',
                        subRoutes: {matrix: {emberIdent: 'matrix', path: '/matrix', crew: 'ptp'}},
                    },
                    periodPlans: {
                        emberIdent: 'periodPlans',
                        path: '/periodPlans',
                        crew: 'ptp',
                        subRoutes: {group: {emberIdent: 'group', path: '/:groupId', crew: 'ptp'}},
                    },
                    lists: {
                        emberIdent: 'lists',
                        path: '/lists',
                        crew: 'foundation',
                        subRoutes: {listFilter: {emberIdent: 'list-filter', path: '/list-filter', crew: 'foundation'}},
                    },
                    institutionOverview: {
                        crew: 'foundation',
                        emberIdent: 'index',
                        path: '/',
                    },
                    institutionOverviewOld: {
                        crew: 'foundation',
                        emberIdent: 'overview',
                        path: '/overview',
                    },
                    institutionOverviewParamerizedOld: {
                        emberIdent: 'overviewParameterized',
                        path: '/overview/:type/:id/childrenAndStaff',
                        crew: 'foundation',
                    },
                    childrenAndStaff: {
                        crew: 'foundation',
                        emberIdent: 'childrenAndStaff',
                        path: '/:type/:id/childrenAndStaff',
                    },
                    subscription: {
                        crew: 'finance',
                        emberIdent: 'subscription',
                        path: '/subscription',
                    },
                    mealPlan: {
                        emberIdent: 'mealPlan',
                        path: '/mealPlan',
                        crew: 'ptp',
                        subRoutes: {
                            oldVersion: {
                                emberIdent: 'oldVersion',
                                path: '/oldVersion',
                                crew: 'ptp',
                            },
                            createMealPlan: {
                                emberIdent: 'createMealPlan',
                                path: '/create',
                                crew: 'ptp',
                            },
                            viewMealPlan: {
                                emberIdent: 'viewMealPlan',
                                path: '/:mealPlanId',
                                crew: 'ptp',
                            },
                        },
                    },
                    mealBooking: {
                        emberIdent: 'mealBooking',
                        path: '/mealBooking',
                        crew: 'ptp',
                    },
                    customStatus: {
                        emberIdent: 'customStatus',
                        path: '/customStatus',
                        crew: 'foundation',
                    },
                    vacationPeriod: {
                        emberIdent: 'vacationPeriod',
                        path: '/vacationPeriod/:vacationId',
                        crew: 'stat',
                    },
                    newDepartment: {
                        emberIdent: 'newDepartment',
                        path: '/newDepartment',
                        crew: 'foundation',
                    },
                    vacationPlanning: {
                        emberIdent: 'vacationPlanning',
                        path: '/vacationPlanning',
                        crew: 'stat',
                    },
                    info: {
                        emberIdent: 'info',
                        path: '/info',
                        crew: 'foundation',
                    },
                    files: {
                        emberIdent: 'files',
                        path: '/files',
                        crew: 'foundation',
                    },
                    institutionSettings: {
                        emberIdent: 'institutionSettings',
                        path: '/institutionSettings',
                        crew: 'foundation',
                    },
                    manageGroups: {
                        emberIdent: 'manageGroups',
                        path: '/manageGroups',
                        crew: 'foundation',
                    },
                    editTag: {
                        emberIdent: 'editTag',
                        path: '/editTag/:tagId',
                        crew: 'foundation',
                    },
                    manageCheckinStatements: {
                        emberIdent: 'manageCheckinStatements',
                        path: '/manageCheckinStatements',
                        crew: 'ptp',
                    },
                    volumePricingSettings: {
                        emberIdent: 'volumePricingSettings',
                        path: '/volumePricingSettings',
                        crew: 'finance',
                    },
                    mealSettings: {
                        emberIdent: 'mealSettings',
                        path: '/mealSettings',
                        crew: 'ptp',
                    },
                    groupMoves: {
                        emberIdent: 'groupMoves',
                        path: '/groupMoves',
                        crew: 'foundation',
                    },
                    billPayerV2: {
                        emberIdent: 'billPayer',
                        path: '/billPayer/:billPayerId',
                        crew: 'finance',
                        subRoutes: {
                            // This only exists for legacy reasons where backend notifications linked to this subroute.
                            // This will keep Ember happy.
                            paymentMethods: {emberIdent: 'paymentMethods', path: '/paymentMethods', crew: 'finance'},
                            taxStatement: {
                                emberIdent: 'taxStatement',
                                path: '/taxStatement/:selectedYear',
                                crew: 'finance',
                            },
                        },
                    },
                    // TODO: Is this used?
                    accountingSettings: {
                        emberIdent: 'accountingSettings',
                        path: '/accountingSettings',
                        crew: 'finance',
                    },
                    publicFundingReport: {
                        emberIdent: 'publicFundingReport',
                        path: '/publicFundingReport',
                        crew: 'finance',
                    },
                    childBasedFundingReport: {
                        emberIdent: 'funding',
                        path: '/funding',
                        crew: 'finance',
                    },
                    staffing: {
                        emberIdent: 'staffing',
                        path: '/staffing',
                        crew: 'stat',
                        subRoutes: {
                            attendance: {
                                emberIdent: 'staffAttendance',
                                path: '/attendance',
                                crew: 'stat',
                            },
                            attendanceDetails: {
                                emberIdent: 'staffAttendanceDetails',
                                path: '/attendance/details',
                                crew: 'stat',
                            },
                            shiftPlanner: {emberIdent: 'staffSchedule', path: '/staffSchedule', crew: 'stat'},
                            staffHours: {emberIdent: 'staffHours', path: '/staffHours', crew: 'stat'},
                            staffLeave: {emberIdent: 'staffLeave', path: '/staffLeave', crew: 'stat'},
                            staffLeaveV2: {emberIdent: 'staffLeaveV2', path: '/staffLeaveV2', crew: 'stat'},
                            staffRatioReport: {emberIdent: 'staffRatioReport', path: '/staffRatioReport', crew: 'stat'},
                        },
                    },
                    attendance: {
                        emberIdent: 'attendance',
                        path: '/attendance',
                        crew: 'stat',
                        subRoutes: {
                            kinderConnect: {
                                emberIdent: 'kinderConnect',
                                path: '/kinderConnect',
                                crew: 'stat',
                                subRoutes: {
                                    month: {
                                        emberIdent: 'month',
                                        path: '/:yearMonth',
                                        crew: 'stat',
                                        subRoutes: {
                                            export: {
                                                emberIdent: 'export',
                                                path: '/export',
                                                crew: 'stat',
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                    // TODO: Are these used? They all seem to just redirect to childProfile/:childId/activity
                    child: {
                        emberIdent: 'child',
                        path: '/child/:childId',
                        crew: 'foundation',
                        subRoutes: {
                            childData: {emberIdent: 'childData', path: '/childData', crew: 'foundation'},
                            childRelations: {emberIdent: 'childRelations', path: '/childRelations', crew: 'foundation'},
                            childPermissions: {
                                emberIdent: 'childPermissions',
                                path: '/childPermissions',
                                crew: 'foundation',
                            },
                            childHealth: {emberIdent: 'childHealth', path: '/childHealth', crew: 'ptp'},
                            childCalendar: {emberIdent: 'childCalendar', path: '/childCalendar', crew: 'stat'},
                            childPlans: {emberIdent: 'childPlans', path: '/childPlans', crew: 'finance'},
                            childBilling: {emberIdent: 'childBilling', path: '/childBilling', crew: 'finance'},
                            sensitiveInformation: {
                                emberIdent: 'sensitiveInformation',
                                path: '/sensitiveInformation',
                                crew: 'ptp',
                            },
                        },
                    },
                    billingProfiles: {
                        emberIdent: 'billingProfiles',
                        path: '/billingProfiles',
                        crew: 'finance',
                        subRoutes: {
                            attendanceSchedules: {
                                emberIdent: 'attendanceSchedules',
                                path: '/attendanceSchedules',
                                crew: 'finance',
                            },
                            fundingGrants: {emberIdent: 'fundingGrants', path: '/fundingGrants', crew: 'finance'},
                        },
                    },
                    automaticInvoicing: {
                        emberIdent: 'automaticInvoicing',
                        path: '/automaticInvoicing',
                        crew: 'finance',
                    },
                    kitabuli: {
                        emberIdent: 'kitabuli',
                        path: '/kitabuli',
                        crew: 'finance',
                    },
                    finance: {
                        emberIdent: 'finance',
                        path: '/finance',
                        crew: 'finance',
                    },
                    // TODO: Is this used?
                    financeReports: {
                        emberIdent: 'financeReports',
                        path: '/financeReports',
                        crew: 'finance',
                    },
                    invoiceSettings: {
                        emberIdent: 'invoiceSettings',
                        path: '/invoiceSettings',
                        crew: 'finance',
                    },
                    discountSettings: {
                        emberIdent: 'discountSettings',
                        path: '/discountSettings',
                        crew: 'finance',
                    },
                    // TODO: Is this used?
                    revenueAndDebt: {
                        emberIdent: 'revenueAndDebt',
                        path: '/revenueAndDebt',
                        crew: 'finance',
                    },
                    closingDays: {
                        emberIdent: 'closingDays',
                        path: '/closingDays',
                        crew: 'stat',
                    },
                    // TODO: Is this used?
                    revenueDetails: {
                        emberIdent: 'revenueDetails',
                        path: '/revenueDetails/:month',
                        crew: 'finance',
                    },
                    appReport: {
                        emberIdent: 'appReport',
                        path: '/appReport',
                        crew: 'foundation',
                    },
                    manageAgeGroups: {
                        emberIdent: 'manageAgeGroups',
                        path: '/manageAgeGroups',
                        crew: 'stat', // TODO: Is this correct?
                    },
                    manageChildren: {
                        emberIdent: 'manageChildren',
                        path: '/manageChildren',
                        crew: 'foundation',
                    },
                    parentBookingSettings: {
                        emberIdent: 'parentBookingSettings',
                        path: '/parentBookingSettings',
                        crew: 'finance',
                    },
                    paymentsSettings: {
                        emberIdent: 'paymentsSettings',
                        path: '/paymentsSettings',
                        crew: 'finance',
                    },
                    fundingSettings: {
                        emberIdent: 'fundingSettings',
                        path: '/fundingSettings',
                        crew: 'finance',
                    },
                    revenueReport: {
                        emberIdent: 'revenueReport',
                        path: '/revenueReport',
                        crew: 'finance',
                    },
                    debtReport: {
                        emberIdent: 'debtReport',
                        path: '/debtReport',
                        crew: 'finance',
                    },
                    payments: {
                        emberIdent: 'payments',
                        path: '/payments',
                        crew: 'finance',
                    },
                    invoicesReport: {
                        emberIdent: 'invoicesReport',
                        path: '/invoicesReport',
                        crew: 'finance',
                    },
                    billPayersReport: {
                        emberIdent: 'billPayersReport',
                        path: '/billPayersReport',
                        crew: 'finance',
                    },
                    depositsReport: {
                        emberIdent: 'depositsReport',
                        path: '/depositsReport',
                        crew: 'finance',
                    },
                    directDebitsReport: {
                        emberIdent: 'directDebitsReport',
                        path: '/directDebitsReport',
                        crew: 'finance',
                    },
                    // TODO: Get rid of this
                    apps: {
                        emberIdent: 'apps',
                        path: '/apps',
                        crew: 'foundation',
                    },
                    batchInvoice: {
                        emberIdent: 'batchInvoice',
                        path: '/batchInvoice',
                        crew: 'finance',
                    },
                    managePlans: {
                        emberIdent: 'managePlans',
                        path: '/managePlans',
                        crew: 'finance',
                    },
                    kibigOverview: {
                        emberIdent: 'kibigOverview',
                        path: '/kibigOverview',
                        crew: 'finance',
                        subRoutes: {
                            children: {
                                emberIdent: 'children',
                                path: '/children',
                                crew: 'finance',
                                subRoutes: {
                                    month: {
                                        emberIdent: 'month',
                                        path: '/:yearMonth',
                                        crew: 'finance',
                                    },
                                },
                            },
                            employees: {
                                emberIdent: 'employees',
                                path: '/employees',
                                crew: 'finance',
                            },
                        },
                    },
                    occupancyPlanner: {
                        emberIdent: 'occupancyPlanner',
                        path: '/occupancyPlanner',
                        crew: 'stat',
                    },
                    childDevelopment: {
                        emberIdent: 'childDevelopment',
                        path: '/childDevelopment',
                        crew: 'ptp',
                        subRoutes: {
                            progressReport: {emberIdent: 'progressReport', path: '/progressReport', crew: 'ptp'},
                            cohortCompare: {emberIdent: 'cohortCompare', path: '/cohortCompare', crew: 'ptp'},
                            nextSteps: {emberIdent: 'nextSteps', path: '/nextSteps', crew: 'ptp'},
                            twoYearChecks: {emberIdent: 'twoYearChecks', path: '/twoYearChecks', crew: 'ptp'},
                            tally: {emberIdent: 'tally', path: '/tally', crew: 'ptp'},
                            groupObservation: {
                                emberIdent: 'groupObservation',
                                path: '/groupObservation',
                                crew: 'ptp',
                            },
                        },
                    },
                    bookings: {
                        emberIdent: 'bookings',
                        path: '/bookings',
                        crew: 'finance',
                        subRoutes: {
                            purchases: {emberIdent: 'purchases', path: '/purchases', crew: 'finance'},
                        },
                    },
                    bookingPatternBuilder: {
                        emberIdent: 'bookingPatternBuilder',
                        path: '/bookingPatternBuilder',
                        crew: 'finance',
                    },
                    bulkPatternFlow: {
                        emberIdent: 'bulkPatternFlow',
                        path: '/bulkPatternFlow',
                        crew: 'finance',
                    },
                    roomPlanning: {
                        emberIdent: 'roomPlanning',
                        path: '/roomPlanning',
                        crew: 'stat',
                    },
                    inquiries: {
                        emberIdent: 'inquiries',
                        path: '/inquiries',
                        crew: 'stat',
                    },
                    inquiriesV2: {
                        emberIdent: 'inquiries-v2',
                        path: '/inquiries-v2',
                        crew: 'stat',
                    },
                    occupancyReport: {
                        emberIdent: 'occupancyReport',
                        path: '/occupancyReport',
                        crew: 'stat',
                    },
                    occupancy: {
                        emberIdent: 'occupancy',
                        path: '/occupancy',
                        crew: 'stat',
                    },
                    forms: {
                        emberIdent: 'forms',
                        path: '/forms',
                        crew: 'stat',
                        subRoutes: {
                            newForm: {
                                emberIdent: 'newForm',
                                path: '/newForm',
                                crew: 'stat',
                            },
                            formBuilder: {
                                emberIdent: 'builder',
                                path: '/builder/:formId',
                                crew: 'stat',
                            },
                            submissionResponse: {
                                emberIdent: 'submissionResponse',
                                path: '/:formId/submissionResponse/:submissionId',
                                crew: 'stat',
                            },
                            formManagement: {
                                emberIdent: 'management',
                                path: '/management',
                                crew: 'stat',
                            },
                        },
                    },
                    billingActivityOverview: {
                        emberIdent: 'billingActivityOverview',
                        path: '/billingActivityOverview',
                        crew: 'finance',
                    },
                    attendanceStatistics: {
                        emberIdent: 'attendanceStatistics',
                        path: '/attendanceStatistics',
                        crew: 'stat',
                    },
                    // On the off-chance that people had made bookmarks for the new debt report
                    // we'll redirect back to the "normal" /debtReport route. At least for a while.
                    debtReportV2: {
                        emberIdent: 'debtReportV2',
                        path: '/debtReportV2',
                        crew: 'finance',
                    },
                    itemsAndPricing: {
                        emberIdent: 'itemsAndPricing',
                        path: '/itemsAndPricing',
                        crew: 'finance',
                    },
                    immunizations: {
                        emberIdent: 'immunizations',
                        path: '/immunizations',
                        crew: 'ptp',
                        subRoutes: {
                            onboarding: {
                                emberIdent: 'onboarding',
                                path: '/onboarding',
                                crew: 'ptp',
                            },
                        },
                    },
                    medicationForms: {
                        emberIdent: 'medicationForms',
                        path: '/medicationForms',
                        crew: 'ptp',
                    },
                    staffQualifications: {
                        emberIdent: 'staffQualifications',
                        path: '/staffQualifications',
                        crew: 'foundation',
                    },
                    famlyPay: {
                        emberIdent: 'famlyPay',
                        path: '/famlyPay',
                        crew: 'finance',
                        subRoutes: {
                            payouts: {
                                emberIdent: 'payouts',
                                path: '/payouts',
                                crew: 'finance',
                            },
                            fees: {
                                emberIdent: 'fees',
                                path: '/fees',
                                crew: 'finance',
                            },
                        },
                    },
                    bulkImportPayment: {
                        emberIdent: 'importPayments',
                        path: '/importPayments',
                        crew: 'finance',
                        subRoutes: {
                            splitPayments: {
                                emberIdent: 'splitPayments',
                                path: '/splitPayments/:paymentsFeedId',
                                crew: 'finance',
                            },
                            protoPayments: {
                                emberIdent: 'protoPayments',
                                path: '/protoPayments/:paymentsFeedId',
                                crew: 'finance',
                            },
                        },
                    },
                    headcount: {
                        emberIdent: 'headcount',
                        path: '/headcount',
                        crew: 'ptp',
                    },
                    headcountReport: {
                        emberIdent: 'headcountReport',
                        path: '/headcountReport',
                        crew: 'ptp',
                    },
                    editGroup: {
                        emberIdent: 'editGroup',
                        path: '/editGroup/:groupId',
                        crew: 'foundation',
                    },
                    healthAndSafeguarding: {
                        emberIdent: 'healthAndSafeguarding',
                        path: '/healthAndSafeguarding',
                        crew: 'ptp',
                        subRoutes: {
                            immunizations: {emberIdent: 'immunizations', path: '/immunizations', crew: 'ptp'},
                            medicationForms: {emberIdent: 'medicationForms', path: '/medicationForms', crew: 'ptp'},
                            headcount: {emberIdent: 'headcount', path: '/headcount', crew: 'ptp'},
                        },
                    },
                    safeguarding: {
                        emberIdent: 'safeguarding',
                        path: '/safeguarding',
                        crew: 'ptp',
                    },
                    openid: {
                        emberIdent: 'openid',
                        path: '/openid',
                        crew: 'foundation',
                        subRoutes: {
                            createProvider: {emberIdent: 'createProvider', path: '/createProvider', crew: 'foundation'},
                        },
                    },
                    manageGroupRules: {
                        emberIdent: 'manageGroupRules',
                        path: '/manageGroupRules',
                        crew: 'foundation',
                    },
                    lessonPlans: {emberIdent: 'lessonPlans', path: '/lessonPlans', crew: 'ptp'},
                    curriculums: {
                        emberIdent: 'curriculums',
                        path: '/curriculums',
                        crew: 'ptp',
                        subRoutes: {
                            create: {
                                emberIdent: 'create',
                                path: '/create',
                                crew: 'ptp',
                                subRoutes: {
                                    duplicate: {
                                        emberIdent: 'duplicate',
                                        path: '/:curriculumId',
                                        crew: 'ptp',
                                    },
                                },
                            },
                            show: {
                                emberIdent: 'show',
                                path: '/:curriculumId',
                                crew: 'ptp',
                            },
                        },
                    },
                    manageEmployeeRights: {
                        emberIdent: 'manageEmployeeRights',
                        path: '/manageEmployeeRights',
                        crew: 'foundation',
                        subRoutes: {
                            index: {emberIdent: 'index', path: '/', crew: 'foundation'},
                            roles: {emberIdent: 'roles', path: '/roles', crew: 'foundation'},
                            permissions: {emberIdent: 'permissions', path: '/permissions', crew: 'foundation'},
                        },
                    },
                    employee: {
                        emberIdent: 'employee',
                        path: '/employee/:employeeId',
                        crew: 'stat',
                        subRoutes: {
                            employeeData: {
                                emberIdent: 'employeeData',
                                path: '/employeeData',
                                crew: 'stat',
                            },
                            employeeRelations: {
                                emberIdent: 'employeeRelations',
                                path: '/employeeRelations',
                                crew: 'stat',
                            },
                            employeeLeave: {
                                emberIdent: 'employeeLeave',
                                path: '/employeeLeave',
                                crew: 'stat',
                            },
                            about: {
                                emberIdent: 'about',
                                path: '/about',
                                crew: 'stat',
                            },
                            employeeKibig: {
                                emberIdent: 'employeeKibig',
                                path: '/employeeKibig',
                                crew: 'stat',
                            },
                            employeeCalendar: {
                                emberIdent: 'employeeCalendar',
                                path: '/employeeCalendar',
                                crew: 'stat',
                            },
                        },
                    },
                    childImport: {
                        emberIdent: 'childImport',
                        path: '/childImport',
                        crew: 'ptp',
                    },
                    kitaImport: {
                        emberIdent: 'kitaImport',
                        path: '/kitaImport',
                        crew: 'ptp',
                    },
                    staff: {
                        emberIdent: 'staff',
                        path: '/staff',
                        crew: 'foundation', // TODO: is this correct?
                    },
                    children: {
                        emberIdent: 'children',
                        path: '/children',
                        crew: 'foundation',
                    },
                    noAccess: {
                        emberIdent: 'noAccess',
                        path: '/noAccess',
                        crew: 'foundation',
                    },
                    financeOnboarding: {
                        emberIdent: 'financeOnboarding',
                        path: '/financeOnboarding',
                        crew: 'growth',
                    },
                    /*
                        Since we added Famly Store to the institution routes, the link in the old nav was broken.
                        This was due to different way of handling routes and nav links in the old and new nav.
                        The solution is to keep both routes (at least for some time) until we are sure, nobody is on the old nav.
                    */
                    famlyStore: {
                        emberIdent: 'famlyStore',
                        path: '/famlyStore',
                        subRoutes: {
                            packagePurchase: {
                                emberIdent: 'packagePurchase',
                                path: '/packagePurchase/:initialPackageId/:billingCycle',
                                crew: 'growth',
                            },
                            partners: {
                                emberIdent: 'perks',
                                path: '/perks',
                                crew: 'growth',
                            },
                            packagePricing: {
                                emberIdent: 'packagePricing',
                                path: '/packagePricing',
                                crew: 'growth',
                            },
                            featureStore: {
                                emberIdent: 'featureStore',
                                path: '/featureStore',
                                crew: 'growth',
                            },
                            templates: {
                                emberIdent: 'templates',
                                path: '/templates',
                                crew: 'growth',
                            },
                        },
                        crew: 'growth',
                    },
                    contentReports: {
                        emberIdent: 'contentReports',
                        path: '/contentReports',
                        crew: 'foundation',
                    },
                    financeLandingPage: {
                        emberIdent: 'financeLandingPage',
                        path: '/financeLandingPage',
                        crew: 'growth',
                    },
                    attendanceLandingPage: {
                        emberIdent: 'attendanceLandingPage',
                        path: '/attendanceLandingPage',
                        crew: 'growth',
                    },
                    learningLandingPage: {
                        emberIdent: 'learningLandingPage',
                        path: '/learningLandingPage',
                        crew: 'growth',
                    },
                    childrenLandingPage: {
                        emberIdent: 'childrenLandingPage',
                        path: '/childrenLandingPage',
                        crew: 'growth',
                    },
                },
            },
            /*
            |---------------------------------------------------------------------------------
            | Organization routes
            |---------------------------------------------------------------------------------
            */
            organization: {
                emberIdent: 'organization',
                path: '/organization/:organizationId',
                crew: 'foundation',
                subRoutes: {
                    // TODO: Should we delete this? It doesn't work
                    home: {
                        path: '/home',
                        emberIdent: 'home',
                        crew: 'foundation',
                    },
                    // TODO: Should we delete this? It doesn't work
                    homeCustomization: {
                        emberIdent: 'customization',
                        path: '/customization',
                        crew: 'foundation',
                    },
                    apiTokens: {
                        path: '/apiTokens',
                        emberIdent: 'apiTokens',
                        crew: 'foundation',
                    },
                    security: {
                        path: '/security',
                        emberIdent: 'security',
                        crew: 'foundation',
                    },
                    integrations: {
                        path: '/integrations',
                        emberIdent: 'integrations',
                        crew: 'foundation',
                        subRoutes: {
                            watchMeGrow: {
                                path: '/watchMeGrow',
                                emberIdent: 'watchMeGrow',
                                crew: 'ptp',
                            },
                        },
                    },
                    openid: {
                        path: '/openid',
                        emberIdent: 'openid',
                        crew: 'foundation',
                        subRoutes: {
                            createProvider: {
                                path: '/createProvider',
                                emberIdent: 'createProvider',
                                crew: 'foundation',
                            },
                        },
                    },
                    inquiriesV2: {
                        emberIdent: 'inquiries-v2',
                        path: '/inquiries-v2',
                        crew: 'stat',
                    },
                    staffing: {
                        emberIdent: 'staffing',
                        path: '/staffing',
                        crew: 'stat',
                        subRoutes: {
                            staffLeaveV2: {emberIdent: 'staffLeaveV2', path: '/staffLeaveV2', crew: 'stat'},
                        },
                    },
                    settings: {
                        path: '/settings',
                        emberIdent: 'settings',
                        crew: 'foundation',
                    },
                    // TODO: Is this used?
                    subscription: {
                        path: '/subscription',
                        emberIdent: 'subscription',
                        crew: 'finance',
                    },
                    // TODO: Is this used?
                    manageEmployees: {
                        path: '/manageEmployees',
                        emberIdent: 'manageEmployees',
                        crew: 'foundation', // TODO: Is this right?
                        subRoutes: {
                            overview: {
                                path: '/',
                                emberIdent: 'index',
                                crew: 'foundation',
                            },
                            roles: {
                                path: '/roles',
                                emberIdent: 'roles',
                                crew: 'foundation',
                            },
                            permissions: {
                                path: '/permissions',
                                emberIdent: 'permissions',
                                crew: 'foundation',
                            },
                        },
                    },
                    newArea: {
                        path: '/newArea',
                        emberIdent: 'newArea',
                        crew: 'foundation',
                    },
                    revenueReport: {
                        path: '/revenueReport',
                        emberIdent: 'revenueReport',
                        crew: 'finance',
                    },
                    // TODO: Is this used?
                    billingActivityOverview: {
                        path: '/billingActivityOverview',
                        emberIdent: 'billingActivityOverview',
                        crew: 'finance',
                    },
                    payments: {
                        path: '/payments',
                        emberIdent: 'payments',
                        crew: 'finance',
                    },
                    invoicesReport: {
                        path: '/invoicesReport',
                        emberIdent: 'invoicesReport',
                        crew: 'finance',
                    },
                    billPayersReport: {
                        path: '/billPayersReport',
                        emberIdent: 'billPayersReport',
                        crew: 'finance',
                    },
                    directDebitsReport: {
                        emberIdent: 'directDebitsReport',
                        path: '/directDebitsReport',
                        crew: 'finance',
                    },
                    occupancy: {
                        path: '/occupancy',
                        emberIdent: 'occupancy',
                        crew: 'stat',
                    },
                    occupancyReport: {
                        path: '/occupancyReport',
                        emberIdent: 'occupancyReport',
                        crew: 'stat',
                    },
                    forms: {
                        emberIdent: 'forms',
                        path: '/forms',
                        crew: 'stat',
                        subRoutes: {
                            formBuilder: {
                                emberIdent: 'builder',
                                path: '/builder/:formId',
                                crew: 'stat',
                            },
                            submissionResponse: {
                                emberIdent: 'submissionResponse',
                                path: '/:formId/submissionResponse/:submissionId',
                                crew: 'stat',
                            },
                            formManagement: {
                                emberIdent: 'management',
                                path: '/management',
                                crew: 'stat',
                            },
                        },
                    },
                    publicFundingReport: {
                        path: '/publicFundingReport',
                        emberIdent: 'publicFundingReport',
                        crew: 'finance',
                    },
                    balanceReport: {
                        path: '/balanceReport',
                        emberIdent: 'balanceReport',
                        crew: 'finance',
                    },
                    insights: {
                        path: '/insights',
                        emberIdent: 'insights',
                        crew: 'foundation',
                    },
                    organizationOverview: {
                        path: '/',
                        emberIdent: 'index',
                        crew: 'foundation',
                    },
                    dashboard: {
                        path: '/dashboard',
                        emberIdent: 'dasboard',
                        crew: 'foundation',
                    },
                    contentReports: {
                        emberIdent: 'contentReports',
                        path: '/contentReports',
                        crew: 'foundation',
                    },
                    // TODO: Is this used?
                    finance: {
                        emberIdent: 'finance',
                        path: '/finance',
                        crew: 'finance',
                    },
                    automaticInvoicing: {
                        emberIdent: 'automaticInvoicing',
                        path: '/automaticInvoicing',
                        crew: 'finance',
                    },
                    curriculums: {
                        emberIdent: 'curriculums',
                        path: '/curriculums',
                        crew: 'ptp',
                        subRoutes: {
                            create: {
                                emberIdent: 'create',
                                path: '/create',
                                crew: 'ptp',
                                subRoutes: {
                                    duplicate: {
                                        emberIdent: 'duplicate',
                                        path: '/:curriculumId',
                                        crew: 'ptp',
                                    },
                                },
                            },
                            show: {
                                emberIdent: 'show',
                                path: '/:curriculumId',
                                crew: 'ptp',
                            },
                        },
                    },
                    bulkImportPayment: {
                        emberIdent: 'importPayments',
                        path: '/importPayments',
                        crew: 'finance',
                        subRoutes: {
                            splitPayments: {
                                emberIdent: 'splitPayments',
                                path: '/splitPayments/:paymentsFeedId',
                                crew: 'finance',
                            },
                            protoPayments: {
                                emberIdent: 'protoPayments',
                                path: '/protoPayments/:paymentsFeedId',
                                crew: 'finance',
                            },
                        },
                    },
                },
            },
            loginAs: {
                emberIdent: 'loginAs',
                path: '/loginAs',
                crew: 'foundation',
            },
            endpointDebugging: {
                emberIdent: 'endpointDebugging',
                path: '/endpointDebugging',
                crew: 'foundation',
            },
            financeDebugging: {
                emberIdent: 'financeDebugging',
                path: '/financeDebugging',
                crew: 'finance',
                subRoutes: {
                    childId: {
                        emberIdent: 'childId',
                        path: '/:childId',
                        crew: 'finance',
                    },
                },
            },
            modernFamlyDebugging: {
                emberIdent: 'modernFamlyDebugging',
                path: '/modernFamlyDebugging',
                crew: 'foundation',
            },
        },
    },
} as const satisfies RouteConfigType;

export default RouteConfig;
