const PLATFORMS = {
    WEB: 'html',
    IOS: 'ios',
    ANDROID: 'android',
    DOCKER: 'docker',
};

const ENV = {
    TEST: 'test',
    LOCAL: 'development',
    STAGING: 'staging',
    PRODUCTION: 'production',
};

// This allows ES6 files to import the exports
exports.PLATFORMS = PLATFORMS;
exports.ENV = ENV;

module.exports = {
    PLATFORMS,
    ENV,
};
