import {generatePath, matchPath} from 'react-router-dom';

import {type NavigationFunction} from 'web-app/react/contexts/navigation';
import {buildQueryParams} from 'web-app/react/hooks/use-update-query-params';
import {exhaustiveCheck, hasValue} from 'web-app/util/typescript';
import {extractPathFromHash} from 'web-app/util/url';
import * as SentryService from 'web-app/services/sentry';

import RouteConfig, {
    type Crew,
    type RouteConfigPath,
    type Route as RouteConfigRoute,
    type RouteConfigType,
} from './route-config';

type Path = string;
type PathWithCrew = [Path, Crew | undefined];
/**
 * Returns a flattened array of all paths based on RouteConfig
 */
export const flattenRouteConfig = (routeConfig: RouteConfigType): PathWithCrew[] => {
    return Object.values(routeConfig).flatMap(route => flattenRoute(route));
};

/**
 * Recursive function to crawl through nested RouteConfigRoutes
 */
const flattenRoute = (route: RouteConfigRoute, prepend: string = ''): PathWithCrew[] => {
    if (route.emberIdent === 'index') {
        return [];
    }

    const path = `${prepend}${route.path}`;
    const pathWithCrew = [path, route.crew] satisfies PathWithCrew;
    if (!hasValue(route.subRoutes)) {
        return [pathWithCrew];
    }

    return [
        pathWithCrew,
        ...Object.values(route.subRoutes).flatMap(route => {
            return flattenRoute(route, path);
        }),
    ];
};

// Compute flattened route config once
const flattenedRouteConfigWithCrews = flattenRouteConfig(RouteConfig);
const flattenedRouteConfig = flattenedRouteConfigWithCrews.map(([path]) => path);

export const findCrewFromPath = (inputPath: string): Crew | undefined => {
    return flattenedRouteConfigWithCrews.find(([path]) => path === inputPath)?.[1];
};

export const validateStringAsRouteConfigPath = (
    stringPath: string,
    defaultPath: RouteConfigPath = '/login',
): RouteConfigPath => (flattenedRouteConfig.includes(stringPath) ? (stringPath as RouteConfigPath) : defaultPath);

/**
 * @private use findPathFromUrl instead. This is export for test purposes
 */
export const testableFindPathFromUrl = (url: string, flattenedConfig: Path[]): Path | undefined => {
    return flattenedConfig.find(path =>
        hasValue(
            matchPath(url, {
                path,
                exact: true,
                strict: false,
            }),
        ),
    );
};

/**
 *
 * @param url following the structure "/account/institution/cool-inst-id/some/route"
 * @param flattenedConfig should not be used, implemented to support stubbing for tests
 * @returns the matching react-router path - e.g. "/account/institution/:institutionId/some/route"
 */
export const findPathFromUrl = (url: string) => testableFindPathFromUrl(url, flattenedRouteConfig);

type QueryParams = {[paramKey: string]: string | undefined | null};

/**
 * @deprecated Use `withPath` instead
 */
export const makeBuildSubroutePath =
    (parentPath: string) =>
    /**
     * @deprecated Use `withPath` instead
     */
    (routePath: string | undefined = '') => {
        return `${parentPath}${routePath}`;
    };

const hasQueryParams = (value: any): value is {queryParams: QueryParams} => {
    return typeof value === 'object' && typeof value.queryParams === 'object';
};

/**
 * Small convenience function to build a query params string.
 *
 * Filters out nulls and undefined, as that's also Ember's behavior (which the goal is to replace)
 *
 * @param queryParams
 * An object representing the query params (e.g. {selectedId: 'c08729a6-2195-4e60-8318-6cf246b33b4e, type: 'all'})
 *
 * @returns
 * A query params string (e.g. "selectedId=c08729a6-2195-4e60-8318-6cf246b33b4e&type=all")
 */
const makeQueryParams = (queryParams: QueryParams) => {
    const filteredQueryParams = Object.fromEntries(
        Object.entries(queryParams)
            .map(([key, value]) => (hasValue(value) ? ([key, value] as const) : null))
            .filter(hasValue),
    );

    return buildQueryParams(filteredQueryParams).toString();
};

type FunctionUsage =
    | {
          type: 'query-params-only';
          queryParams: QueryParams;
      }
    | {
          type: 'path-without-segments-and-no-query-params';
          path: string;
      }
    | {
          type: 'path-without-segments-and-with-query-params';
          path: string;
          queryParams: QueryParams;
      }
    | {
          type: 'path-with-segments-and-no-query-params';
          path: string;
          pathSegmentMapping: object;
      }
    | {
          type: 'path-with-segments-and-with-query-params';
          path: string;
          pathSegmentMapping: object;
          queryParams: QueryParams;
      }
    | {
          type: 'unsupported';
          error: string;
      };

// Converts from dot routes like "account.institution.billPayer"
// to "/account/institution/:institutionId/billPayer/:billPayerId" using the provided route config
const convertFromDotPathToReactRouterPath = (routeConfig: RouteConfigType, dotPath: string) => {
    const splitPattern = dotPath.split('.');

    const pathPattern = splitPattern
        // Filters out "index" if it appears as the last element - the index route is a "synthetic" Ember route that
        // our RouteConfig doesn't support - we'd essentially have to add an "index" entry on each "node" to be sure to cover
        // all possible usage and references.
        .filter((dotSegment, index) => {
            return !(dotSegment === 'index' && index === splitPattern.length - 1);
        })
        .reduce(
            ({routeSegments, routeConfig}, dotSegment) => {
                const subRouteConfig =
                    Object.values(routeConfig).find(route => route.emberIdent === dotSegment) ?? null;

                if (!subRouteConfig) {
                    throw Error(
                        `Ember navigation replacement: No route element found for segment ${dotSegment}. Possible values in this location in the RouteConfig object are: ${Object.keys(
                            routeConfig,
                        ).join(', ')}`,
                    );
                }

                return {
                    routeSegments: [...routeSegments, subRouteConfig.path],
                    routeConfig: subRouteConfig.subRoutes ?? {},
                };
            },
            {routeSegments: new Array<string>(), routeConfig},
        )
        .routeSegments.join('');

    return pathPattern;
};

/**
 * We assume that the candidate is already a route if it doesn't have "dots",
 * and if it starts with a slash.
 */
const isAlreadyRoute = (candidate: string) => {
    const notDotNotation = !hasValue(candidate.match(/\./));
    const startsWithSlash = candidate.startsWith('/');

    return notDotNotation && startsWithSlash;
};

export const determineUsage = (
    routeConfig: RouteConfigType,
    firstArgument: string | object,
    otherArguments: (string | object)[],
): FunctionUsage => {
    if (typeof firstArgument === 'object') {
        // Check if we're passed an object as the first argument
        // This is kind of a special case as it's (usually) when we only want to change query params
        // and stick to the current route.
        if (hasQueryParams(firstArgument)) {
            return {
                type: 'query-params-only',
                queryParams: firstArgument.queryParams,
            };
        } else {
            return {
                type: 'unsupported',
                error: `Ember navigation replacement: When "firstArgument" is an object, we expect it to contain query params. It was ${JSON.stringify(
                    firstArgument,
                )}`,
            };
        }
    }

    // Check whether the first argument is already a "proper route" (e.g. /account/feed).
    // If so, we don't need to convert from dot notation to route.
    const path = isAlreadyRoute(firstArgument)
        ? firstArgument
        : convertFromDotPathToReactRouterPath(routeConfig, firstArgument);

    // Find route segments to be "filled" by the other provided params.
    // E.g. "/account/institution/:institutionId/billPayer/:billPayerId" yields ["institutionId", "billPayerId"]
    const dynamicPathSegments = [...path.matchAll(/:(\w+)/g)].map(match => match?.[1] ?? undefined).filter(hasValue);

    const secondArgument = otherArguments[0];
    const allOtherArgumentsAreStrings = otherArguments.every(param => typeof param === 'string');
    const allButLastArgumentsAreStrings = [...otherArguments].slice(0, -1).every(param => typeof param === 'string');
    const lastArgument = otherArguments[otherArguments.length - 1];
    const lastArgumentIsObject = typeof lastArgument === 'object';

    if (otherArguments.length === 0 && dynamicPathSegments.length === 0) {
        return {
            type: 'path-without-segments-and-no-query-params',
            path,
        };
    } else if (typeof secondArgument === 'object' && otherArguments.length === 1) {
        if (!hasQueryParams(secondArgument)) {
            return {
                type: 'path-with-segments-and-no-query-params',
                path,
                pathSegmentMapping: secondArgument,
            };
        }

        if (dynamicPathSegments.length > 0) {
            const {queryParams, ...pathSegmentMapping} = secondArgument;

            return {
                type: 'path-with-segments-and-with-query-params',
                path,
                pathSegmentMapping,
                queryParams,
            };
        } else {
            return {
                type: 'path-without-segments-and-with-query-params',
                path,
                queryParams: secondArgument.queryParams,
            };
        }
    } else if (allOtherArgumentsAreStrings || (allButLastArgumentsAreStrings && lastArgumentIsObject)) {
        // In this case, we'll "assemble" the segments in the pathPattern and build the mapping ourselves

        const numberOfParams = (() => {
            if (allOtherArgumentsAreStrings) {
                return otherArguments.length;
            } else if (allButLastArgumentsAreStrings && lastArgumentIsObject) {
                return otherArguments.length - 1;
            } else {
                return 0;
            }
        })();

        if (dynamicPathSegments.length !== numberOfParams) {
            return {
                type: 'unsupported',
                error: `Ember navigation replacement: Unexpected mismatch between the number of path segments (${dynamicPathSegments.length}) and the number of provided params (${numberOfParams})`,
            };
        }

        // Builds the mapping between the path segments and passed params
        // Like Ember does, it's assumed that the order of path segments matches the order of passed params.
        // E.g. if "pathPattern" is "/account/institution/:institutionId/billPayer/:billPayerId", then it's expected
        // that the "otherArguments" first and second element are an institutionId and a billPayerId respectively.
        const pathSegmentMapping = dynamicPathSegments.reduce((mapping, segment, index) => {
            return {
                ...mapping,
                [segment]: otherArguments[index] as string,
            };
        }, {});

        if (hasQueryParams(lastArgument)) {
            return {
                type: 'path-with-segments-and-with-query-params',
                path,
                pathSegmentMapping,
                queryParams: lastArgument.queryParams,
            };
        } else {
            return {
                type: 'path-with-segments-and-no-query-params',
                path,
                pathSegmentMapping,
            };
        }
    } else {
        return {
            type: 'unsupported',
            error: `Ember navigation replacement: "params" was an unexpected combination of strings and objects: '${JSON.stringify(
                otherArguments,
            )}'`,
        };
    }
};

/**
 * The goal of this function is to replicate Ember's navigation functions by passing the arguments that we would
 * normally pass to Ember's generateUrl, transitionTo, and replaceWith - and produce a "proper" path that we can
 * then use with e.g. ReactRouter's navigation tools.
 *
 * If the function is passed an "invalid" combination of params, we throw an exception.
 * This can happen if firstArgument is  'account.institution.billPayer' (which via the passed route config will be resolved to
 * '/account/institution/:institutionId/billPayer/:billPayerId'), and otherArguments is ['944794aa-7196-4663-9e04-d16c0cead114'],
 * then we don't have a value for :billPayerId, and an exception is thrown.
 *
 * @param routeConfig
 * A tree structure of the route structure to use in the conversion.
 *
 * @param firstArgument
 * The route to convert - can be either a string in the "dot format" (e.g. "account.institution.childDevelopment")
 * or an object with query params.
 *
 * @param otherArguments
 * The "rest" of the arguments passed to generateUrl, transitionTo, and replaceWith. We've tried to replicate the
 * different ways Ember's route functions' arguments can be used.
 *
 * @returns
 * A fully "populated" route incl. query params
 * (e.g. /account/institution/76725fd9-2f74-4ce2-af31-c0da1def58fa/childDevelopment?selection=off)
 */
export const convertDotNotationToPath = (
    routeConfig: RouteConfigType,
    firstArgument: string | object,
    ...otherArguments: (string | object)[]
) => {
    const usage = determineUsage(routeConfig, firstArgument, otherArguments);

    switch (usage.type) {
        case 'query-params-only':
            return `${extractPathFromHash(window.location.hash)}?${makeQueryParams(usage.queryParams)}`;
        case 'path-without-segments-and-no-query-params':
            return usage.path;
        case 'path-without-segments-and-with-query-params':
            return `${usage.path}?${makeQueryParams(usage.queryParams)}`;
        case 'path-with-segments-and-no-query-params':
            return generatePath(usage.path, usage.pathSegmentMapping);
        case 'path-with-segments-and-with-query-params':
            return `${generatePath(usage.path, usage.pathSegmentMapping)}?${makeQueryParams(usage.queryParams)}`;
        case 'unsupported':
            throw Error(usage.error);
        default:
            exhaustiveCheck(usage);
            return '';
    }
};

/**
 * Convenience function to "build" a replacement for Ember's navigation function, but with support for a fallback
 * if an unsupported combination of arguments are spotted as part of route conversion from Ember's dot-notation
 * (e.g. account.institution.childDevelopment) to a "proper" route
 * (e.g. /account/institution/30673ff5-3cf5-4f20-b519-e923d1c34791/childDevelopment).
 *
 * @param name
 * The name of the function from Ember that this will replace. Used for logging purposes.
 *
 * @param routeConfig
 * A tree structure of the route structure to use in the conversion.
 *
 * @param navigateFunction
 * Function responsible for the actual navigation. Will be passed route generated in `convertDotNotationToPath`
 *
 * @param emberFallbackFunction
 * Function to be invoked if the route generation in `convertDotNotationToPath` hits an unsupported or unexpected
 * combination of arguments
 *
 * @returns
 * A replacement for one of Ember's navigation functions
 */
export const buildNavigationFunctionReplacement =
    (
        name: 'generateUrl' | 'transitionTo' | 'replaceWith',
        routeConfig: RouteConfigType,
        navigateFunction: (path: string) => string,
        emberFallbackFunction: NavigationFunction,
    ): NavigationFunction =>
    (firstArgument: string | object, ...otherArguments: (string | object)[]) => {
        try {
            const path = convertDotNotationToPath(routeConfig, firstArgument, ...otherArguments);

            return navigateFunction(path);
        } catch (error) {
            // We want the Cypress tests to fail immediately if we spot
            // an "unsupported" usage of the new navigation helpers
            if (window.Cypress) {
                throw error;
            }

            // Falling back (at least for now) to Ember's navigation function
            const resultFromFallback = emberFallbackFunction(firstArgument, ...otherArguments);

            SentryService.captureException(error, {
                extra: {
                    functionName: name,
                    firstArgument: JSON.stringify(firstArgument),
                    otherArguments: JSON.stringify(otherArguments),
                    resultFromFallback: JSON.stringify(resultFromFallback),
                },
            });

            return resultFromFallback;
        }
    };

/**
 * @private use findRouteTemplateFromUncleanUrl instead. This is export for test purposes
 */
export const testableFindRouteTemplateFromUncleanUrl = (findPath: typeof findPathFromUrl) => (uncleanUrl: string) => {
    const urlWithoutHash = uncleanUrl.split('#')[1];
    const urlWithoutQueryParams = urlWithoutHash?.split('?')[0];
    const routeTemplate = urlWithoutQueryParams ? findPath(urlWithoutQueryParams) : undefined;

    return routeTemplate;
};

/**
 * Converts an "unclean" url to the matching route template.
 *
 * @param uncleanUrl The unclean url. E.g. `#/account/feed/68479d1d-f8c9-4cd3-aa31-ea0c08330315?foo=bar
 *
 * @returns The matching route template. E.g. `/account/feed/:institutionId/
 */
export const findRouteTemplateFromUncleanUrl = testableFindRouteTemplateFromUncleanUrl(findPathFromUrl);
