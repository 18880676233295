import LocalStorageService from './local-storage';

const LOCAL_STORAGE_KEY = 'isTranslating';

export const isTranslating = (): boolean => {
    const isTranslating = LocalStorageService.getFromLocalStorage(LOCAL_STORAGE_KEY);

    return isTranslating === 'yes';
};

export const setIsTranslating = (isTranslating: boolean): void =>
    LocalStorageService.saveToLocalStorage(LOCAL_STORAGE_KEY, isTranslating ? 'yes' : 'no');
