var isLocalStorageAvailable = function () {
    try {
        var x = '__storage_test__';
        window.localStorage.setItem(x, x);
        window.localStorage.removeItem(x);
        return true;
    } catch (e) {
        return false;
    }
};

if (isLocalStorageAvailable()) {
    // As we can't use the LocalStorageService here, we compare against the JSON
    // encoded string. Hence the single+double quoted string
    var isTranslating = localStorage.getItem('famly.isTranslating') === '"yes"';

    if (isTranslating) {
        window._jipt = [];
        window._jipt.push(['project', 'famly-app']);
        window._jipt.push([
            'escape',
            function () {
                localStorage.setItem('famly.isTranslating', JSON.stringify('no'));
                window.location.reload();
            },
        ]);

        var script = document.createElement('script');
        script.src = 'https://cdn.crowdin.com/jipt/jipt.js';
        document.head.appendChild(script);
    }
}
