/**
 * The delimiters, abbreviations, and ordinals are configured based on the
 * users locale.
 *
 * The currency is based on the country where the institution is placed.
 */

// tslint:disable:no-bitwise
// tslint:disable:variable-name

import numeral from 'numeral';

export default () => {
    numeral.register('locale', 'en_GB', {
        delimiters: {
            thousands: ',',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: number => {
            const b = number % 10;
            return ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        },
        currency: {
            symbol: '£',
        },
    });

    numeral.register('locale', 'da_DK', {
        delimiters: {
            thousands: '.',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'mio',
            billion: 'mia',
            trillion: 'b',
        },
        ordinal: () => '.',
        currency: {
            symbol: 'DKK ',
        },
    });

    numeral.register('locale', 'de_DE', {
        delimiters: {
            thousands: '.',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: () => '.',
        currency: {
            symbol: '€',
        },
    });

    numeral.register('locale', 'en_US', {
        delimiters: {
            thousands: ',',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: number => {
            const b = number % 10;
            return ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        },
        currency: {
            symbol: '$',
        },
    });

    numeral.register('locale', 'de_CH', {
        delimiters: {
            thousands: "'",
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: () => '.',
        currency: {
            symbol: 'CHF ',
        },
    });

    numeral.register('locale', 'no_NO', {
        delimiters: {
            thousands: ' ',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: () => '.',
        currency: {
            symbol: 'kr',
        },
    });

    // From: https://github.com/adamwdraper/Numeral-js/blob/7de892ffb438af6e63b9c4f6aff0c9bc3932f09f/locales/es.js
    const spanish = {
        delimiters: {
            thousands: '.',
            decimal: ',',
        },
        abbreviations: {
            thousand: 'k',
            million: 'mm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: number => {
            var b = number % 10;
            return b === 1 || b === 3
                ? 'er'
                : b === 2
                ? 'do'
                : b === 7 || b === 0
                ? 'mo'
                : b === 8
                ? 'vo'
                : b === 9
                ? 'no'
                : 'to';
        },
        currency: {
            symbol: '$',
        },
    };
    numeral.register('locale', 'es_ES', spanish);
    numeral.register('locale', 'es_US', spanish);

    /**
     * The locales hereafter are not currently selectable in the UI but are here for completeness
     */

    numeral.register('locale', 'ar_AE', {
        delimiters: {
            thousands: ',',
            decimal: '.',
        },
        abbreviations: {
            thousand: 'k',
            million: 'm',
            billion: 'b',
            trillion: 't',
        },
        ordinal: number => {
            const b = number % 10;
            return ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
        },
        currency: {
            symbol: 'AED',
        },
    });
};
