const ALL = require('./constants');

const {PLATFORMS, ENV} = ALL;

const APP_DEVELOPMENT_HOST = 'app.famly.local';

// .de is on purpose first to make sure we don't send data from german customers to .co domain
const extraTLDs = ['.de'];
const baseTLD = '.co';
const allTLDs = [...extraTLDs, baseTLD];

const brightHorizonsHostname = 'familyapp.brighthorizons.co.uk';
const brightHorizonsImgService = `https://img.${brightHorizonsHostname}`;
const brightHorizonsApiService = `https://famlyapi.${brightHorizonsHostname}`;

const busyBeesHostname = 'app.busybees.com';
const busyBeesImgService = `https://img.${busyBeesHostname}`;
const busyBeesApiService = `https://famlyapi.${busyBeesHostname}`;

const determineMobileApp = platform => platform === PLATFORMS.ANDROID || platform === PLATFORMS.IOS;

const getAPIConfigs = (env, platform, isDevelopment, whitelabelAppId, remoteDevHost) => {
    const isWeb = platform === PLATFORMS.WEB;
    const isMobileApp = determineMobileApp(platform);

    const appDevelopmentUrl = remoteDevHost ? `https://${remoteDevHost}` : `http://${APP_DEVELOPMENT_HOST}`;
    const targetOrigin = (isMobileApp && isDevelopment) || remoteDevHost ? appDevelopmentUrl : '';

    const localConfig = {
        key: '.local',
        isBase: true,
        app: '/',
        api: `${targetOrigin}/api`,
        famlyapi: `${targetOrigin}/famlyapi`,
        graphql: `${targetOrigin}/graphql`,
    };

    const stagingTestingConfigs = allTLDs.map(tld => {
        const isBase = tld === baseTLD;
        return {
            key: tld,
            isBase,
            app: isWeb && isDevelopment ? appDevelopmentUrl : `https://app.tryfamly${tld}`,
            api: isBase ? `https://tryfamly${tld}/api` : `https://app.tryfamly${tld}/api`,
            famlyapi: isBase ? `https://tryfamly${tld}/famlyapi` : `https://app.tryfamly${tld}/famlyapi`,
            graphql: isBase ? `https://tryfamly${tld}/graphql` : `https://app.tryfamly${tld}/graphql`,
        };
    });

    const mobileAppStagingTestingConfigs = allTLDs.map(tld => {
        const isBase = tld === baseTLD;
        return {
            key: tld,
            isBase,
            app: isWeb && isDevelopment ? appDevelopmentUrl : `https://app.tryfamly${tld}`,
            api: `https://api.tryfamly${tld}/`,
            famlyapi: `https://famlyapi.tryfamly${tld}/famlyapi`,
            graphql: `https://famlyapi.tryfamly${tld}/graphql`,
        };
    });

    const mobileAppBrightHorizonsConfig = [
        {
            key: '.co.uk',
            isBase: true,
            app: `https://${brightHorizonsHostname}`,
            api: `https://api.${brightHorizonsHostname}`,
            famlyapi: `https://famlyapi.${brightHorizonsHostname}/famlyapi`,
            graphql: `https://famlyapi.${brightHorizonsHostname}/graphql`,
        },
    ];

    const mobileAppBusybeesConfig = [
        {
            key: '.com',
            isBase: true,
            app: `https://${busyBeesHostname}`,
            api: `https://api.${busyBeesHostname}`,
            famlyapi: `https://famlyapi.${busyBeesHostname}/famlyapi`,
            graphql: `https://famlyapi.${busyBeesHostname}/graphql`,
        },
    ];
    switch (env) {
        case ENV.TEST:
        case ENV.LOCAL:
            if (isMobileApp) {
                return remoteDevHost ? [localConfig] : mobileAppStagingTestingConfigs;
            }
            return [localConfig];
        // The German Azure setup runs docker in production mode on staging
        case ENV.STAGING:
            // We have two staging builds. One for Azure that uses docker together with our old CloudFront/S3 setup
            if (platform === PLATFORMS.DOCKER || (isWeb && !isDevelopment)) {
                return [localConfig];
            }
            // ...and one for development and the mobile app
            else if (isMobileApp) {
                return mobileAppStagingTestingConfigs;
            } else {
                return stagingTestingConfigs;
            }
        case ENV.PRODUCTION: {
            // We have three production builds. One for Azure that uses docker together with our old CloudFront/S3 setup
            if (platform === PLATFORMS.DOCKER || (isWeb && !isDevelopment)) {
                return [localConfig];
            }
            // ...one for the mobile apps apps that circumvent CloudFront to save $$$ as they don't have to worry about CORS
            else if (isMobileApp) {
                switch (whitelabelAppId) {
                    case 'brighthorizons':
                        return mobileAppBrightHorizonsConfig;
                    case 'busybees':
                        return mobileAppBusybeesConfig;
                    default:
                        return allTLDs.map(tld => ({
                            key: tld,
                            isBase: tld === baseTLD,
                            app: isWeb && isDevelopment ? appDevelopmentUrl : `https://app.famly${tld}`,
                            api: `https://api.famly${tld}/api`,
                            famlyapi: `https://famlyapi.famly${tld}/famlyapi`,
                            graphql: `https://famlyapi.famly${tld}/graphql`,
                        }));
                }
            }
            // ...and one for development
            else {
                return allTLDs.map(tld => ({
                    key: tld,
                    isBase: tld === baseTLD,
                    app: isWeb && isDevelopment ? appDevelopmentUrl : `https://app.famly${tld}`,
                    api: `https://app.famly${tld}/api`,
                    famlyapi: `https://app.famly${tld}/famlyapi`,
                    graphql: `https://app.famly${tld}/graphql`,
                }));
            }
        }
        default:
            throw new Error(`Unknown environment: ${env}`);
    }
};

exports.getAPIConfigs = getAPIConfigs;
exports.determineMobileApp = determineMobileApp;
exports.APP_DEVELOPMENT_HOST = APP_DEVELOPMENT_HOST;
exports.allTLDs = allTLDs;
exports.brightHorizonsImgService = brightHorizonsImgService;
exports.brightHorizonsApiService = brightHorizonsApiService;
exports.busyBeesImgService = busyBeesImgService;
exports.busyBeesApiService = busyBeesApiService;
