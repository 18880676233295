/* eslint-disable camelcase */
import {setLocale} from 'web-app/util/locale';

// eslint-disable-next-line no-restricted-syntax
export enum SupportedLanguages {
    da_DK = 'da_DK',
    en_US = 'en_US',
    en_GB = 'en_GB',
    de_DE = 'de_DE',
    no_NO = 'no_NO',
    es_ES = 'es_ES',
    es_US = 'es_US',
}

export const defaultLocale = SupportedLanguages.en_US;

const makeLocaleSetup =
    (
        getLocaleFromApi: () => Promise<{locale: SupportedLanguages}>,
        savedLocale: SupportedLanguages | null | undefined,
    ) =>
    () => {
        return new Promise<string>(resolve => {
            if (savedLocale) {
                if (Object.values(SupportedLanguages).includes(savedLocale)) {
                    resolve(savedLocale);
                } else {
                    // tslint:disable-next-line:no-console
                    console.warn(
                        `Failed to load unknown locale ${savedLocale} from localstorage. Defaulting to ${defaultLocale}`,
                    );
                    resolve(defaultLocale);
                }
            } else if (!navigator.onLine) {
                resolve(defaultLocale);
            } else {
                let alreadyResolved = false;

                getLocaleFromApi().then(
                    data => {
                        if (!alreadyResolved) {
                            alreadyResolved = true;
                            resolve(data.locale);
                        }
                    },
                    () => {
                        if (!alreadyResolved) {
                            alreadyResolved = true;
                            resolve(defaultLocale);
                        }
                    },
                );

                setTimeout(() => {
                    if (!alreadyResolved) {
                        alreadyResolved = true;
                        resolve(defaultLocale);
                    }
                }, 4000);
            }
        }).then(locale => {
            return setLocale(locale);
        });
    };

export default makeLocaleSetup;
