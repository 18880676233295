// Allows us to target ie10
var doc = document.documentElement;
doc.setAttribute('data-useragent', navigator.userAgent);

// Allows us to target Firefox 33
var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
if (isFirefox) {
    var match = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    var version = match ? parseInt(match[1]) : 0;

    if (version < 34) {
        var element = document.getElementById('deprecationOverlay');
        element.style.cssText = 'display: block;';

        var chromeIconElement = document.getElementById('chromeIcon');
        chromeIconElement.style.backgroundImage = 'url("img/browser-support/chrome.png")';

        var firefoxIconElement = document.getElementById('firefoxIcon');
        firefoxIconElement.style.backgroundImage = 'url("img/browser-support/firefox.png")';
    }
}
