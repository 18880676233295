import {gql} from '@apollo/client';

export const KeepAlive = gql`
    mutation KeepAlive {
        me {
            keepAlive {
                afterSeconds
            }
        }
    }
`;
