type Dictionary<T> = {[key in keyof T]: T[key]};
export type Primitive = string | number | boolean | undefined | null;

/**
 * Super simple shallow diff which simply compares values in object a with values in object b
 */
const shallowDiff = <A, B>(a: Dictionary<A>, b: Dictionary<B>) => {
    if (!a || !b || typeof a !== 'object' || typeof b !== 'object') {
        throw Error('Invalid arguments given to shallow-diff');
    }
    const changes: {[key in keyof typeof a]?: typeof a[key]} = Object.keys(a).reduce(
        (reduction, key) => (a[key] === b[key] ? reduction : {...reduction, [key]: b[key]}),
        {},
    );
    const additions: {[key in keyof typeof b]?: typeof b[key]} = Object.keys(b).reduce(
        (reduction, key) => (a[key] === undefined ? {...reduction, [key]: b[key]} : reduction),
        {},
    );
    return {
        ...changes,
        ...additions,
    };
};

/**
 * Compare two values. Returns true if they are different. Uses `shallowDiff` for objects.
 */
export function shallowCompare(a: string, b: string): boolean;
export function shallowCompare(a: number, b: number): boolean;
export function shallowCompare(a: boolean, b: boolean): boolean;
export function shallowCompare(a: null, b: null): boolean;
export function shallowCompare(a: undefined, b: undefined): boolean;
export function shallowCompare<A, B>(a: Dictionary<A>, b: Dictionary<B>): boolean;
export function shallowCompare(a, b) {
    if (isPrimitive(a) || isPrimitive(b)) {
        return a !== b;
    }
    return Object.keys(shallowDiff(a, b)).length > 0;
}

export default shallowDiff;

export const isPrimitive = (prop): prop is Primitive =>
    typeof prop === 'string' ||
    typeof prop === 'number' ||
    typeof prop === 'boolean' ||
    prop === undefined ||
    prop === null;
