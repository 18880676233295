import {getCurrentHub} from '@sentry/core';
import type {SeverityLevel} from '@sentry/browser';
import type {Extras} from '@sentry/types';

/**
 * Utility function used internally to check if Sentry object
 * can capture exceptions and messages properly.
 *
 * @property isSentryUsable
 * @type Ember.ComputedProperty
 */
export const isSentryUsable = () => {
    // From https://github.com/getsentry/sentry-go/issues/9
    // Yes, I know it's the Go SDK, but it works as of writing this.
    const isInitialized = Boolean(getCurrentHub()?.getClient());

    return isInitialized;
};

const isError = (element: any) => {
    return element instanceof Error;
};

type Data = {
    extra?: Extras;
    fingerprint?: string[];
    level?: SeverityLevel;
};

type CaptureExceptionData = Pick<Data, 'extra' | 'level'>;

/**
 * Sends an exception to Sentry if it's initialized.
 *
 * If Sentry hasn't been initialized, we log the error to console.
 *
 * @param error The error to send to Sentry
 * @param data Optional data object
 */
export const captureException = (error: any, data?: CaptureExceptionData) => {
    if (isSentryUsable()) {
        const exception = isError(error) ? error : JSON.stringify(error);
        getCurrentHub().withScope(scope => {
            if (data && data.extra) {
                const extra = data.extra;

                Object.keys(extra).map(extraKey => {
                    scope.setExtra(extraKey, extra[extraKey]);
                });
            }
            scope.setLevel(data && data.level ? data.level : 'error');

            getCurrentHub().captureException(exception);
        });
    } else {
        // eslint-disable-next-line no-console
        console.error('Sentry is not initialized', error);
    }
};

/**
 * Tries to have Sentry capture message, or send it to console.
 *
 * @method captureMessage
 * @param  {String} message The message to capture
 * @param  {Object} data Optional data object
 * @return {Boolean}
 */
export const captureMessage = (message: string, data?: Data): boolean => {
    if (isSentryUsable()) {
        getCurrentHub().withScope(scope => {
            if (data && data.extra) {
                const extra = data.extra;

                Object.keys(extra).map(extraKey => {
                    scope.setExtra(extraKey, extra[extraKey]);
                });
            }

            if (data?.fingerprint) {
                scope.setFingerprint(data.fingerprint);
            }

            scope.setLevel(data && data.level ? data.level : 'error');

            getCurrentHub().captureMessage(message);
        });
        return true;
    }

    return false;
};
