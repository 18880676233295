import i18next, {type InitOptions, type TFunction} from 'i18next';
import i18nextHttpApi from 'i18next-http-backend';

import LocalStorageService from 'web-app/services/local-storage';
import SHARED_ENVIRONMENT_CONFIG from 'web-app/shared/environment';
import {getWhitelabelName} from 'web-app/shared/white-labeling-helpers';

const getDefaultLanguage = () => {
    const isProductionEnvironment = SHARED_ENVIRONMENT_CONFIG.IS_DEVELOPMENT === false;
    const dontShowBaseTranslations = Boolean(LocalStorageService.getFromLocalStorage('dontShowBaseTranslations'));

    if (isProductionEnvironment || dontShowBaseTranslations) {
        return 'en-US';
    }

    const PRODUCTION_ORIGINS = [
        'app.famly',
        'signin.famly',
        'localhost', // Mobile application
    ];

    const isProductionOrigin = PRODUCTION_ORIGINS.some(origin => window.location.origin.includes(origin));

    if (isProductionOrigin) {
        return 'en-JM';
    }

    return 'en-US';
};

type Setup = (translationsLoadPath: string) => Promise<TFunction>;

const setup: Setup = async translationsLoadPath => {
    const defaultLanguage = getDefaultLanguage();

    const options: InitOptions = {
        // v21 of i18next switched to JSON v4 specification
        // To avoid having to update all translation keys to new spec
        // we are toggling on v3 compatibility option
        // https://www.i18next.com/misc/migration-guide#json-format-v4-pluralization
        compatibilityJSON: 'v3',
        interpolation: {
            escapeValue: false,
            // In v21 "skipOnVariables" defaults to true
            // As we are using nested translations keys
            // we are changing it to false
            // https://www.i18next.com/misc/migration-guide#skiponvariables-true
            skipOnVariables: false,
            defaultVariables: {
                whitelabelName: getWhitelabelName(),
            },
        },
        fallbackLng: {
            'de-CH': ['de-DE'],
            'no-NO': ['da-DK'],
            'es-US': ['es-ES'],
            default: [defaultLanguage],
        },
        load: 'currentOnly',
        supportedLngs: ['da-DK', 'en-US', 'en-GB', 'de-DE', 'eo-UY', 'en-JM', 'no-NO', 'es-ES'],
        backend: {
            crossDomain: false,
            withCredentials: false,
            loadPath: translationsLoadPath,
        },
    };

    return await i18next.use(i18nextHttpApi).init(options);
};

export default setup;
