import {merge, type Observable, timer} from 'rxjs';
import {debounceTime, mapTo, scan, skip, switchMap, switchMapTo, take, takeUntil} from 'rxjs/operators';

import {type SpanStreamValue} from './sentry-tracing';

type Milliseconds = number;

/**
 *
 * @param startTransaction$ an observable that represents the start of a Transaction.
 * @param idleTimeout number of milliseconds that is used to determine if the transaction should stop
 * when nothing has happened. A timer is started when the Transaction begins, and is restarted when
 * a Span is registered.
 * @param spanStream$ an observable that emits when a Span attempts to regsiter itself to a Transaction if one is active
 *
 * @returns `Observable<'NO_SPANS_REGISTERED'>` if no Spans have been registered within `idleTimeout`
 * @returns `Observable<{timeOfLastSpanCompleted: unixTimestamp, spanId: string}>` of last span to be registered within `idleTimeout`
 */
export const withLatestSpanOrTimeout$ = ({
    startTransaction$,
    idleTimeout,
    spanStream$,
}: {
    startTransaction$: Observable<any>;
    idleTimeout: Milliseconds;
    spanStream$: Observable<SpanStreamValue>;
}) => {
    return startTransaction$.pipe(
        // Using switchMapTo to "restart" the inner observable every time we start a Transaction
        switchMapTo(
            merge(
                // Start a timer to emit "NO_SPANS_REGISTERED" but stop it if Span is registered
                timer(idleTimeout).pipe(mapTo('NO_SPANS_REGISTERED' as const), takeUntil(spanStream$)),
                // Use debounceTime to emit when no Spans have been registered for "idleTimeout"
                withLatestCompletedSpan$({spanStream$, idleTimeout}),
            ).pipe(take(1)),
        ),
    );
};

/**
 *
 * @param spanStream$ an observable that emits when a Span attempts to regsiter itself to a Transaction if one is active
 * @returns `Observable<{timeOfLastSpanCompleted: unixTimestamp, spanId: string}>` of the last-to-finish Span
 */
const withLatestCompletedSpan$ = ({
    spanStream$,
    idleTimeout,
}: {
    spanStream$: Observable<SpanStreamValue>;
    idleTimeout: Milliseconds;
}) => {
    // Observable that emits when no Span has been registered after "idleTimeout" ms
    const spanRegistrationStopped$ = spanStream$.pipe(debounceTime(idleTimeout));

    return spanStream$.pipe(
        // Let values pass until spanRegistrationStopped$ emits
        takeUntil(spanRegistrationStopped$),
        /**
         * Use `scan` to collect spanFinisher$ observables into an array.
         * This is used to "reverse race" all the spanFinisher$ observables and emit the one that finishes last.
         */
        scan<Observable<{finishedAt: number; spanId: string}>>((reduction, value) => [...reduction, value], []),
        /**
         * Add debounce to only let values pass when `idleTimeout` has passed and we've collected all Spans for the Transaction
         */
        debounceTime(idleTimeout),
        // Finally do a "reverse race" by letting all spanFinisher$ observables emit, but skip all but the last
        switchMap(spanCompletions => {
            return merge(...spanCompletions).pipe(skip(spanCompletions.length - 1));
        }),
    );
};
