import {hasValue} from './typescript';

export const buildUrlWithQueryParameters = (
    origin: string,
    queryParameters: {[k: string]: string | number} = {},
): string => {
    const queryParamsString = objectToQueryString(queryParameters);

    return `${origin}${queryParamsString ? `?${queryParamsString}` : ''}`;
};

export const objectToQueryString = (queryObject: {[k: string]: string | number} = {}) => {
    const queryParams = new URLSearchParams();

    for (const key of Object.keys(queryObject)) {
        queryParams.append(key, `${queryObject[key]}`);
    }

    return queryParams.toString();
};

// Gets the route in the "hash" part of the url - but ignores query parameters if they exist.
export const extractPathFromHash = (hash: string) => {
    return hash.split('?')[0].replace('#', '');
};

/**
 * This is only exported to be covered by tests
 */
export const getQueryParamsFromHref = (href: string) => {
    const [url, params] = href.split('?');
    return [url, new URLSearchParams(params)] as const;
};

/**
 * Use this to read query parameters with the browser's API.
 *
 * "When should I use this?"
 * - NOT if you're in a react-router dependant route then use `useQueryParams`
 * - DO use this if your feature lives outside react-router
 * - fromHash: set it to true if you want to read query parameters safely in the context of the react router hash routing
 */
export const getQueryParams = (fromHash?: boolean) =>
    getQueryParamsFromHref(fromHash ? window.location.hash : window.location.href);

/**
 * Use this to update query parameters with the browser's API.
 *
 * "When should I use this?"
 * - NOT if you're in a react-router dependant route then use `useUpdateQueryParams`
 * - DO use this if your feature lives outside react-router or is used across routes
 */
export const updateQueryParams = (
    cb: (params: URLSearchParams) => URLSearchParams,
    options?: {replace?: boolean; onlyHash?: boolean},
) => {
    const [url, params] = getQueryParams(options?.onlyHash);
    const searchParams = cb(params);

    const addParamsToURL = (baseURL: string, params: URLSearchParams) => {
        if (params.toString().length === 0) {
            return baseURL;
        }
        return `${baseURL}?${params.toString()}`;
    };

    const newUrl = addParamsToURL(url, searchParams);

    if (hasValue(options) && options.replace) {
        window.history.replaceState(undefined, window.document.title, newUrl);
    } else if (options?.onlyHash) {
        window.location.hash = newUrl;
    } else {
        window.location.href = newUrl;
    }
};

export const isBadNetworkError = (error: Error) => {
    // "TypeError: Failed to fetch" means there is a connectivity issue between the client and the service
    return error instanceof TypeError && error.message === 'Failed to fetch';
};

const URL_REGEX = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
);

export const isValidURL = (input: string) => Boolean(input.match(URL_REGEX));

export function removeLeadingHashtagFromNavigationHashIfNeeded(hash: string): string {
    if (hash.startsWith('#')) {
        return hash.split('#')[1];
    }
    return hash;
}
