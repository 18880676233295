import {catchError, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {from, identity} from 'rxjs';

import setupI18n from 'web-app/setup/i18next-setup';
import setupLocale from 'web-app/setup/locale-setup';
import ENV from 'web-app/config/environment';
import {captureMessage} from 'web-app/services/sentry';
import PlatformHelper from 'web-app/helpers/platform-helper';

/**
 * Currently this file is imported both by Ember's application route, AND
 * by the React.Router application route. To avoid cyclical imports, and/or changing the current
 * import order, this code has to live in its own file as long as both implementations exist alongside eachother.
 */

const fetchTranslationObservable$ = (version: string) => from(setupI18n(`locales/{{lng}}-${version}.json`));

/**
 * Attempt to fetch translations based on baked-in version.
 * If we can't fetch translations based on baked-in version, we fetch the
 * latest version via /release.txt and use it to get the latest translations
 */
const fetchTranslationsWithFallback$ = fetchTranslationObservable$(encodeURIComponent(ENV.APP.version)).pipe(
    // Don't use fallback strategy on mobile apps
    PlatformHelper.isMobileApp()
        ? identity
        : catchError(() =>
              // Ideally we would only fall back on 404, but i18next-xhr-backend doesn't forward the network error
              from(fetch(ENV.releaseUrl)).pipe(
                  mergeMap(response => response.text()),
                  tap(version => {
                      captureMessage('Unable to fetch translation files', {
                          extra: {
                              failedVersion: ENV.APP.version,
                              fallbackVersion: version,
                          },
                      });
                  }),
                  mergeMap(fetchTranslationObservable$),
              ),
          ),
);

// Perform async i18next setup
export const withTranslationsReady$ = fetchTranslationsWithFallback$.pipe(tap(setupLocale), shareReplay());
