// eslint-disable-next-line no-restricted-syntax
export enum ESPIRA_ICON_CONFIG {
    SPIRE_PORTALEN_COLOR_GREEN = '#80BC00',
    SPIRE_PORTALEN_COLOR_RED = '#CB333B',
    SPIRE_PORTALEN_COLOR_WHITE = '#FFFFFF',
}

// Used for determining white-labeling in mobile apps
// eslint-disable-next-line no-restricted-syntax
export enum WhitelabelAppId {
    espira = 'espira',
    kido = 'kido',
    brighthorizons = 'brighthorizons',
    nfamilyclub = 'nfamilyclub',
    kindred = 'kindred',
    monkeypuzzle = 'monkeypuzzle',
    grandiruk = 'grandiruk',
    nuffieldhealth = 'nuffieldhealth',
    kinderzimmeruk = 'kinderzimmeruk',
    cpctreehouse = 'cpctreehouse',
    cpcorchards = 'cpcorchards',
    busybees = 'busybees',
}

// Used for determining white-labeling based on url
export const WhitelabelUrl: {[id in WhitelabelAppId]: string} = {
    espira: 'spireportalen.no',
    kido: 'kidoapps.co',
    brighthorizons: 'familyapp.brighthorizons.co.uk',
    nfamilyclub: 'app.nfamilyclub.com',
    kindred: 'app.kindrednurseries.co.uk',
    monkeypuzzle: 'mpapp.monkeypuzzledaynurseries.com',
    grandiruk: 'app.grandiruk.com',
    nuffieldhealth: 'nuffynursery.nuffieldhealth.com',
    kinderzimmeruk: 'app.kinderzimmer.co.uk',
    cpctreehouse: 'app.treehouseschools.com',
    cpcorchards: 'app.childrensorchardacademy.com',
    busybees: 'app.busybees.com',
};

export const WhitelabelMobileUrl: {[id in WhitelabelAppId]: string} = {
    espira: 'no.spireportalen.app',
    kido: 'school.kido.app',
    brighthorizons: 'com.brighthorizons.app',
    nfamilyclub: 'com.nfamilyclub.app',
    kindred: 'com.kindred.app',
    monkeypuzzle: 'com.monkeypuzzle.app',
    grandiruk: 'com.grandiruk.app',
    nuffieldhealth: 'com.nuffieldhealth.nursery',
    kinderzimmeruk: 'com.kinderzimmeruk.app',
    cpctreehouse: 'com.treehouseschools.app',
    cpcorchards: 'com.childrensorchardacademy.app',
    busybees: 'com.busybees.app',
};

// eslint-disable-next-line no-restricted-syntax
export enum Whitelabel {
    espira,
    kido,
    brighthorizons,
    nfamilyclub,
    kindred,
    monkeypuzzle,
    grandiruk,
    nuffieldhealth,
    kinderzimmeruk,
    cpctreehouse,
    cpcorchards,
    busybees,
}
