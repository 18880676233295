import AES from 'crypto-js/aes';
import HmacSHA512 from 'crypto-js/hmac-sha512';
import encUtf8 from 'crypto-js/enc-utf8';
import encBase64 from 'crypto-js/enc-base64';

import LocalStorageService from 'web-app/services/local-storage';
import {type Event, type EncryptedEvent} from 'web-app/util/famlytics/types';
import generateUUID from 'web-app/util/uuid';

import {EVENTS_KEY, KEYPART_KEY, HEARTBEAT_KEY} from './constants';

const getAll = () => {
    return (LocalStorageService.getFromLocalStorage(EVENTS_KEY) as {[x: string]: EncryptedEvent[]}) || {};
};

const get = (loginId: string) => {
    return getAll()[loginId] || [];
};

const save = (events: EncryptedEvent[], loginId: string) => {
    const allEvents = getAll();
    allEvents[loginId] = events;
    LocalStorageService.saveToLocalStorage(EVENTS_KEY, allEvents);
};

export const encrypt = (event: Event, key: string) => {
    return AES.encrypt(JSON.stringify(event), key).toString();
};

const getKeyPart = (loginId: string) => {
    const allKeyParts = (LocalStorageService.getFromLocalStorage(KEYPART_KEY) as {[x: string]: string}) || {};
    let storedKeyPart = allKeyParts[loginId];

    if (!storedKeyPart) {
        storedKeyPart = generateUUID();
        allKeyParts[loginId] = storedKeyPart;
        LocalStorageService.saveToLocalStorage(KEYPART_KEY, allKeyParts);
    }

    return storedKeyPart;
};

export const decrypt = (eventCipher: string, key: string) => {
    const bytes = AES.decrypt(eventCipher.toString(), key);

    try {
        const plaintext = bytes.toString(encUtf8);
        const event = JSON.parse(plaintext);
        return event;
    } catch (error) {
        // SentryService.captureMessage('Could not decrypt famlytics event.', {
        //     extra: {
        //         error,
        //         eventCipher,
        //     },
        // });
        return undefined;
    }
};

export const getEncryptionKey = (secret: string, loginId: string, withKeyPart: boolean = true) => {
    const hmac = HmacSHA512(secret, withKeyPart ? getKeyPart(loginId) : loginId);
    return encBase64.stringify(hmac);
};

export const saveEvent = (event: Event, secret: string, loginId: string) => {
    const events = get(loginId);
    events.push({
        event: encrypt(event, getEncryptionKey(secret, loginId)),
        id: event.eventId,
    });
    save(events, loginId);
};

export const peek = (chunk: number, secret: string, loginId: string) => {
    const events = get(loginId);
    const eventsChunk = events.slice(0, chunk);
    return eventsChunk.map(event => {
        return {
            id: event.id,
            event: decrypt(event.event, getEncryptionKey(secret, loginId)),
        };
    });
};

export const remove = (eventIdsToRemove: string[], loginId: string) => {
    if (eventIdsToRemove.length === 0) {
        return;
    }

    const eventsLeft = get(loginId).filter(event => !eventIdsToRemove.includes(event.id));
    save(eventsLeft, loginId);
};

export const removeAll = () => {
    LocalStorageService.clearKey(EVENTS_KEY);
};

export const flush = () => {
    LocalStorageService.clearKey(EVENTS_KEY);
    LocalStorageService.clearKey(KEYPART_KEY);
    LocalStorageService.clearKey(HEARTBEAT_KEY);
};
