import moment, {type Moment} from 'moment-timezone';
import i18next from 'i18next';

const EVENING_HOUR = 18;

export const getShow12HourClock = (): boolean => {
    const m = moment();

    switch (m.locale()) {
        case 'en_US':
            return true;
        default:
            return false;
    }
};

export const momentToDay = (date: Moment | undefined): string | null => {
    if (!date) {
        return null;
    }

    if (!moment.isMoment(date)) {
        throw new TypeError('Value must be undefined or moment');
    } else if (!date.isValid()) {
        return null;
    }

    const day = date.day();
    switch (day) {
        case 0:
            return 'SUNDAY';
        case 1:
            return 'MONDAY';
        case 2:
            return 'TUESDAY';
        case 3:
            return 'WEDNESDAY';
        case 4:
            return 'THURSDAY';
        case 5:
            return 'FRIDAY';
        case 6:
            return 'SATURDAY';
        default:
            throw new Error(`Non-exhaustive switch statement: ${day}`);
    }
};

interface TimeUnits {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const secondsToTimeUnits = (secondsInput: number, showDays = true): TimeUnits => {
    const minutesScale = 60;
    const hoursScale = minutesScale * 60;
    const dayScale = hoursScale * 24;

    const days = showDays ? Math.floor(secondsInput / dayScale) : 0;
    const hours = Math.floor((secondsInput - days * dayScale) / hoursScale);
    const minutes = Math.floor((secondsInput - days * dayScale - hours * hoursScale) / minutesScale);
    const seconds = Math.round(secondsInput - days * dayScale - hours * hoursScale - minutes * minutesScale);

    return {
        days,
        hours,
        minutes,
        seconds,
    };
};

export const secondsToDurations = (secondsInput: number, showDays = true, showSeconds = false): string => {
    const {days, hours, minutes, seconds} = secondsToTimeUnits(secondsInput, showDays);

    let duration = minutes || !hours || !days ? `${minutes}${i18next.t('time.minutesAbbr')}` : '';
    if (hours || days) {
        duration = `${hours}${i18next.t('time.hoursAbbr')} ${duration}`;
    }

    if (showDays && days) {
        duration = `${days}${i18next.t('time.daysAbbr')} ${duration}`;
    }

    if (showSeconds) {
        duration = `${duration} ${seconds}${i18next.t('time.secondsAbbr')}`;
    }

    return duration;
};

export const fromNow = (date: Moment | string | undefined, forceRelative = false, endOfDay = false): string => {
    if (date) {
        const momentDate = endOfDay ? moment(date).endOf('day') : moment(date);
        if (moment().diff(momentDate, 'days') > 2 && !forceRelative) {
            return momentDate.format('lll');
        } else {
            return momentDate.fromNow();
        }
    } else {
        return '';
    }
};

/** The fromNow method uses .format('lll'), which won't display year if it the current one. */
export const fromNowWithYear = (date: Moment | string | undefined): string => {
    if (date) {
        if (moment().diff(moment(date), 'days') > 2) {
            return moment(date).format('LL, LT');
        }
        return moment(date).fromNow();
    }
    return '';
};

export const monthValues =
    'JANUARY-FEBRUARY-MARCH-APRIL-MAY-JUNE-JULY-AUGUST-SEPTEMBER-OCTOBER-NOVEMBER-DECEMBER'.split('-');

/**
 * @deprecated this function produces an off-by-one error with minutes, as the Math.floor does not
 * properly compute single minutes.
 */
export const duration = (hoursInDecimal: number): string => {
    if (hoursInDecimal < 0) {
        hoursInDecimal = -hoursInDecimal;
    }

    const hours = Math.floor(hoursInDecimal);
    const minutes = Math.floor((hoursInDecimal % 1) * 60);

    const hoursString = hours > 0 ? `${hours}${i18next.t('main.hourLabel')}` : '';
    const minutesString = minutes > 0 ? `${minutes}${i18next.t('main.minuteLabel')}` : '';

    return `${hoursString} ${minutesString}`.trim();
};

export const birthday = (dateOfBirth: Moment, short = false): string => {
    if (!moment.isMoment(dateOfBirth)) {
        throw new TypeError('DateOfBirth must be moment');
    }

    const today = moment();

    if (today.isBefore(dateOfBirth)) {
        return i18next.t('notBornYet');
    }

    const totalNumberMonths = today.diff(dateOfBirth, 'months');
    const years = Math.floor(totalNumberMonths / 12);
    const months = totalNumberMonths % 12;

    return [
        years
            ? `${short ? years : ''}${i18next.t(short ? 'time.yearOneLetter' : 'childProfile.ageYear', {count: years})}`
            : null,
        !years || months
            ? `${short ? months : ''}${i18next.t(short ? 'time.monthOneLetter' : 'childProfile.ageMonth', {
                  count: months,
              })}`
            : null,
    ]
        .filter(a => a)
        .join(' ');
};

export interface Option {
    label: string;
    value: string;
}

export const getMonthOptions = (monthsBack: number, totalMonths: number, valueFormat = 'YYYY-MM'): Option[] => {
    const startOfRange = moment().startOf('month').subtract(monthsBack, 'months');
    const options = new Array<Option>();

    for (let i = 0; i < totalMonths; i++) {
        const value = startOfRange.clone().add(i, 'months');
        options.push({
            label: value.format('MMMM YYYY'),
            value: value.format(valueFormat),
        });
    }

    return options;
};

export const getMonthRange = (month: string, format?: string): {from: string; to: string} => {
    const momentMonth = format ? moment(month, format) : moment(month);
    return {
        from: momentMonth.startOf('month').format('YYYY-MM-DD'),
        to: momentMonth.endOf('month').format('YYYY-MM-DD'),
    };
};

// Gets a single day formatted as '1st, 2nd ... 28th'.
// Reason for picking October (in a random year) is to ensure that moment can parse all 31 days if we expand the range.
export const getDayOfTheMonth = (day: number) => {
    if (day < 1 || day > 31) {
        throw new Error('Unsupported day value');
    }
    return moment(`2000-10-${day}`, 'YYYY-MM-DD').format('Do');
};

// Get days formatted as '1st, 2nd ... 28th'.
export const daysOfTheMonthOptions = () => {
    return Array.from({length: 28}, (_, index) => index + 1).map(day => ({
        value: day,
        label: getDayOfTheMonth(day),
    }));
};

// Gets options for the weekdays (Mon-Sun or Sun-Sat based on locale).
// Using isoWeekday to maintain consistency with backend.
export const daysOfTheWeekOptions = () => {
    const now = moment().startOf('week');

    return Array.from({length: 7}, (_, index) => index).map(dayDelta => {
        const weekday = now.clone().add(dayDelta, 'days');

        return {
            value: weekday.isoWeekday() - 1,
            label: weekday.format('dddd'),
        };
    });
};

export const getWeekDiff = (a: Moment, b: Moment) => a.isoWeek() - b.isoWeek();

export const shouldShowWeekNumbers = (moment: Moment) => {
    return moment.locale() === 'da_DK';
};

export function getYesterday(moment: Moment): Moment {
    return moment.subtract(1, 'day');
}

export function isEvening(moment: Moment): boolean {
    return moment.hours() > EVENING_HOUR;
}

export function getYearMonths() {
    return monthValues.map((value, i) => {
        const month = i + 1;
        return {
            label: moment(`1 ${month}`, 'D M').format('ll'),
            value,
        };
    });
}
