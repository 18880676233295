import momentTZ from 'moment-timezone';

import * as StringUtils from 'web-app/util/string';
import {shouldShowWeekNumbers} from 'web-app/util/moment';

export const getDateValidationRegex = locale => {
    switch (locale) {
        case 'de_DE':
        case 'de_CH':
            // DD.MM.YYYY
            return /^\d{2}[.]\d{2}[.]\d{4}$/;
        case 'da_DK':
            // DD/{M}M-YYYY
            return /^\d{2}[\/]\d{1,2}[-]\d{4}$/;
        case 'en_US':
        case 'en_GB':
        default:
            // MM/DD/YYYY
            return /^\d{2}[\/]\d{2}[\/]\d{4}$/;
    }
};

const initMoment = (moment, update = false) => {
    moment.fn.round = function (precision, key, direction) {
        if (typeof direction === 'undefined') {
            direction = 'round';
        }

        const keys = ['Hours', 'Minutes', 'Seconds', 'Milliseconds'];
        const maxValues = [24, 60, 60, 1000];

        // Capitalize first letter
        key = StringUtils.capitalizeFirstLetter(key, true);

        // make sure key is plural
        if (key.indexOf('s', key.length - 1) === -1) {
            key += 's';
        }
        let value = 0;
        let rounded = false;
        let subRatio = 1;
        let maxValue;

        for (const i in keys) {
            const k = keys[i];
            if (k === key && typeof this._d[`get${key}`] === 'function') {
                value = this._d[`get${key}`]();
                maxValue = maxValues[i];
                rounded = true;
            } else if (rounded && typeof this._d[`get${k}`] === 'function') {
                subRatio *= maxValues[i];
                value += this._d[`get${k}`]() / subRatio;
                this._d[`set${k}`](0);
            }
        }

        value = Math[direction](value / precision) * precision;
        value = Math.min(value, maxValue);
        this._d[`set${key}`](value);

        return this;
    };

    moment.fn.ceil = function (precision, key) {
        return this.round(precision, key, 'ceil');
    };

    moment.fn.floor = function (precision, key) {
        return this.round(precision, key, 'floor');
    };

    moment.fn.isWeekend = function () {
        return this.isoWeekday() > 5;
    };

    moment.fn.showWeekNumbers = function () {
        return shouldShowWeekNumbers(this);
    };

    moment.fn.show12HourClock = function () {
        switch (this.locale()) {
            case 'en_US':
                return true;
            default:
                return false;
        }
    };

    (function (factory) {
        factory(moment);
    })(moment => {
        const defineFunc = update ? moment.updateLocale : moment.defineLocale;
        return defineFunc('en_US', {
            longDateFormat: {
                LT: 'h:mma',
                L: 'MM/DD/YYYY',
                LL: 'MMM D YYYY', // format used for datepicker
                LLL: "M/D 'YY",
                LLLL: "M/D 'YY",
                l: 'M/D',
                ll: 'MMM D',
                lll: 'MMM D, LT',
                llll: 'ddd, MMM D',
            },
            ordinalParse: /\d{1,2}(st|nd|rd|th)/,
            ordinal(number) {
                const b = number % 10;
                const output =
                    ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
                return number + output;
            },
        });
    });

    //! moment.js locale configuration
    //! locale : English (United Kingdom) [en-gb]
    //! author : Chris Gedrim : https://github.com/chrisgedrim

    (function (factory) {
        factory(moment);
    })(moment => {
        const defineFunc = update ? moment.updateLocale : moment.defineLocale;
        return defineFunc('en_GB', {
            months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
            monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
            weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
            weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
            weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
            longDateFormat: {
                LT: 'HH:mm',
                LTS: 'HH:mm:ss',
                L: 'DD/MM/YYYY',
                LL: 'D MMM YYYY',
                LLL: 'D MMMM YYYY HH:mm',
                LLLL: 'dddd, D MMMM YYYY HH:mm',
                l: 'D/M',
                ll: 'D MMM',
                lll: 'D MMM, LT',
                llll: 'ddd, D MMM',
            },
            calendar: {
                sameDay: '[Today at] LT',
                nextDay: '[Tomorrow at] LT',
                nextWeek: 'dddd [at] LT',
                lastDay: '[Yesterday at] LT',
                lastWeek: '[Last] dddd [at] LT',
                sameElse: 'L',
            },
            relativeTime: {
                future: 'in %s',
                past: '%s ago',
                s: 'a few seconds',
                m: 'a minute',
                mm: '%d minutes',
                h: 'an hour',
                hh: '%d hours',
                d: 'a day',
                dd: '%d days',
                M: 'a month',
                MM: '%d months',
                y: 'a year',
                yy: '%d years',
            },
            ordinalParse: /\d{1,2}(st|nd|rd|th)/,
            ordinal(number) {
                const b = number % 10;
                const output =
                    ~~((number % 100) / 10) === 1 ? 'th' : b === 1 ? 'st' : b === 2 ? 'nd' : b === 3 ? 'rd' : 'th';
                return number + output;
            },
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4, // The week that contains Jan 4th is the first week of the year.
            },
        });
    });

    // moment.js language configuration
    // language : danish (da)
    // author : Ulrik Nielsen : https://github.com/mrbase

    (function (factory) {
        factory(moment);
    })(moment => {
        const defineFunc = update ? moment.updateLocale : moment.defineLocale;
        return defineFunc('da_DK', {
            months: 'januar_februar_marts_april_maj_juni_juli_august_september_oktober_november_december'.split('_'),
            monthsShort: 'jan_feb_mar_apr_maj_jun_jul_aug_sep_okt_nov_dec'.split('_'),
            weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
            weekdaysShort: 'søn_man_tir_ons_tor_fre_lør'.split('_'),
            weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
            longDateFormat: {
                LT: 'HH:mm',
                L: 'DD/MM-YYYY',
                LL: 'D. MMM YYYY', // format used for datepicker
                LLL: 'D. MMMM YYYY LT',
                LLLL: "D/M 'YY",
                l: 'D/M',
                ll: 'D. MMM',
                lll: 'D. MMM, LT',
                llll: 'ddd, D. MMM',
            },
            calendar: {
                sameDay: '[I dag kl.] LT',
                nextDay: '[I morgen kl.] LT',
                nextWeek: 'dddd [kl.] LT',
                lastDay: '[I går kl.] LT',
                lastWeek: '[sidste] dddd [kl] LT',
                sameElse: 'L',
            },
            relativeTime: {
                future: 'om %s',
                past: '%s siden',
                s: 'få sekunder',
                m: 'et minut',
                mm: '%d minutter',
                h: 'en time',
                hh: '%d timer',
                d: 'en dag',
                dd: '%d dage',
                M: 'en måned',
                MM: '%d måneder',
                y: 'et år',
                yy: '%d år',
            },
            ordinal: '%d.',
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4, // The week that contains Jan 4th is the first week of the year.
            },
        });
    });

    // moment.js locale configuration
    // locale : german (de)
    // author : lluchs : https://github.com/lluchs
    // author: Menelion Elensúle: https://github.com/Oire

    (function (factory) {
        factory(moment);
    })(moment => {
        function processRelativeTime(number, withoutSuffix, key) {
            const format = {
                m: ['eine Minute', 'einer Minute'],
                h: ['eine Stunde', 'einer Stunde'],
                d: ['ein Tag', 'einem Tag'],
                dd: [`${number} Tage`, `${number} Tagen`],
                M: ['ein Monat', 'einem Monat'],
                MM: [`${number} Monate`, `${number} Monaten`],
                y: ['ein Jahr', 'einem Jahr'],
                yy: [`${number} Jahre`, `${number} Jahren`],
            };
            return withoutSuffix ? format[key][0] : format[key][1];
        }

        const defineFunc = update ? moment.updateLocale : moment.defineLocale;
        return defineFunc('de', {
            months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
            monthsShort: 'Jan._Feb._Mär._Apr._Mai._Jun._Jul._Aug._Sep._Okt._Nov._Dez.'.split('_'),
            weekdays: 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
            weekdaysShort: 'So._Mo._Di._Mi._Do._Fr._Sa.'.split('_'),
            weekdaysMin: 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
            longDateFormat: {
                LT: 'HH:mm',
                L: 'DD.MM.YYYY',
                LL: 'D. MMM YYYY', // format used for datepicker
                LLL: 'D. MMMM YYYY LT',
                LLLL: "D/M 'YY",
                l: 'D/M',
                ll: 'D. MMM',
                lll: 'D. MMM, LT',
                llll: 'ddd, D. MMM',
            },
            calendar: {
                sameDay: '[Heute um] LT [Uhr]',
                sameElse: 'L',
                nextDay: '[Morgen um] LT [Uhr]',
                nextWeek: 'dddd [um] LT [Uhr]',
                lastDay: '[Gestern um] LT [Uhr]',
                lastWeek: '[letzten] dddd [um] LT [Uhr]',
            },
            relativeTime: {
                future: 'in %s',
                past: 'vor %s',
                s: 'ein paar Sekunden',
                m: processRelativeTime,
                mm: '%d Minuten',
                h: processRelativeTime,
                hh: '%d Stunden',
                d: processRelativeTime,
                dd: processRelativeTime,
                M: processRelativeTime,
                MM: processRelativeTime,
                y: processRelativeTime,
                yy: processRelativeTime,
            },
            ordinalParse: /\d{1,2}\./,
            ordinal: '%d.',
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4, // The week that contains Jan 4th is the first week of the year.
            },
        });
    });

    (function (factory) {
        factory(moment);
    })(moment => {
        var nb = moment.defineLocale('nb', {
            months: 'januar_februar_mars_april_mai_juni_juli_august_september_oktober_november_desember'.split('_'),
            monthsShort: 'jan._feb._mars_april_mai_juni_juli_aug._sep._okt._nov._des.'.split('_'),
            monthsParseExact: true,
            weekdays: 'søndag_mandag_tirsdag_onsdag_torsdag_fredag_lørdag'.split('_'),
            weekdaysShort: 'sø._ma._ti._on._to._fr._lø.'.split('_'),
            weekdaysMin: 'sø_ma_ti_on_to_fr_lø'.split('_'),
            weekdaysParseExact: true,
            longDateFormat: {
                LT: 'HH:mm',
                LTS: 'HH:mm:ss',
                L: 'DD.MM.YYYY',
                LL: 'D. MMMM YYYY',
                LLL: 'D. MMMM YYYY [kl.] HH:mm',
                LLLL: 'dddd D. MMMM YYYY [kl.] HH:mm',
                llll: 'ddd, D. MMM',
            },
            calendar: {
                sameDay: '[i dag kl.] LT',
                nextDay: '[i morgen kl.] LT',
                nextWeek: 'dddd [kl.] LT',
                lastDay: '[i går kl.] LT',
                lastWeek: '[forrige] dddd [kl.] LT',
                sameElse: 'L',
            },
            relativeTime: {
                future: 'om %s',
                past: '%s siden',
                s: 'noen sekunder',
                ss: '%d sekunder',
                m: 'ett minutt',
                mm: '%d minutter',
                h: 'en time',
                hh: '%d timer',
                d: 'en dag',
                dd: '%d dager',
                M: 'en måned',
                MM: '%d måneder',
                y: 'ett år',
                yy: '%d år',
            },
            dayOfMonthOrdinalParse: /\d{1,2}\./,
            ordinal: '%d.',
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4, // The week that contains Jan 4th is the first week of the year.
            },
        });

        return nb;
    });

    //! moment.js locale configuration
    //! locale : Spanish [es]
    //! author : Julio Napurí : https://github.com/julionc

    (function (factory) {
        factory(moment);
    })(moment => {
        var monthsShortDot = 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
            monthsShort = 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
            monthsParse = [
                /^ene/i,
                /^feb/i,
                /^mar/i,
                /^abr/i,
                /^may/i,
                /^jun/i,
                /^jul/i,
                /^ago/i,
                /^sep/i,
                /^oct/i,
                /^nov/i,
                /^dic/i,
            ],
            monthsRegex =
                /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre|ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i;

        const defineFunc = update ? moment.updateLocale : moment.defineLocale;

        var es = defineFunc('es', {
            months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
                '_',
            ),
            monthsShort: function (m, format) {
                if (!m) {
                    return monthsShortDot;
                } else if (/-MMM-/.test(format)) {
                    return monthsShort[m.month()];
                } else {
                    return monthsShortDot[m.month()];
                }
            },
            monthsRegex: monthsRegex,
            monthsShortRegex: monthsRegex,
            monthsStrictRegex:
                /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre)/i,
            monthsShortStrictRegex:
                /^(ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i,
            monthsParse: monthsParse,
            longMonthsParse: monthsParse,
            shortMonthsParse: monthsParse,
            weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
            weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
            weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
            weekdaysParseExact: true,
            longDateFormat: {
                LT: 'H:mm',
                LTS: 'H:mm:ss',
                L: 'DD/MM/YYYY',
                LL: 'D [de] MMMM [de] YYYY',
                LLL: 'D [de] MMMM [de] YYYY H:mm',
                LLLL: 'dddd, D [de] MMMM [de] YYYY H:mm',
                l: 'DD/MM',
                ll: 'D [de] MMMM',
                lll: 'D [de] MMMM H:mm',
                llll: 'dddd, D [de] MMMM',
            },
            calendar: {
                sameDay: function () {
                    return '[hoy a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                nextDay: function () {
                    return '[mañana a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                nextWeek: function () {
                    return 'dddd [a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                lastDay: function () {
                    return '[ayer a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                lastWeek: function () {
                    return '[el] dddd [pasado a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                sameElse: 'L',
            },
            relativeTime: {
                future: 'en %s',
                past: 'hace %s',
                s: 'unos segundos',
                ss: '%d segundos',
                m: 'un minuto',
                mm: '%d minutos',
                h: 'una hora',
                hh: '%d horas',
                d: 'un día',
                dd: '%d días',
                w: 'una semana',
                ww: '%d semanas',
                M: 'un mes',
                MM: '%d meses',
                y: 'un año',
                yy: '%d años',
            },
            dayOfMonthOrdinalParse: /\d{1,2}º/,
            ordinal: '%dº',
            week: {
                dow: 1, // Monday is the first day of the week.
                doy: 4, // The week that contains Jan 4th is the first week of the year.
            },
            invalidDate: 'Fecha inválida',
        });

        return es;
    });

    //! moment.js locale configuration
    //! locale : Spanish (United States) [es-us]
    //! author : bustta : https://github.com/bustta
    //! author : chrisrodz : https://github.com/chrisrodz

    (function (factory) {
        factory(moment);
    })(moment => {
        var monthsShortDot = 'ene._feb._mar._abr._may._jun._jul._ago._sep._oct._nov._dic.'.split('_'),
            monthsShort = 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
            monthsParse = [
                /^ene/i,
                /^feb/i,
                /^mar/i,
                /^abr/i,
                /^may/i,
                /^jun/i,
                /^jul/i,
                /^ago/i,
                /^sep/i,
                /^oct/i,
                /^nov/i,
                /^dic/i,
            ],
            monthsRegex =
                /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre|ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i;

        const defineFunc = update ? moment.updateLocale : moment.defineLocale;

        return defineFunc('es-us', {
            months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split(
                '_',
            ),
            monthsShort: function (m, format) {
                if (!m) {
                    return monthsShortDot;
                } else if (/-MMM-/.test(format)) {
                    return monthsShort[m.month()];
                } else {
                    return monthsShortDot[m.month()];
                }
            },
            monthsRegex: monthsRegex,
            monthsShortRegex: monthsRegex,
            monthsStrictRegex:
                /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre)/i,
            monthsShortStrictRegex:
                /^(ene\.?|feb\.?|mar\.?|abr\.?|may\.?|jun\.?|jul\.?|ago\.?|sep\.?|oct\.?|nov\.?|dic\.?)/i,
            monthsParse: monthsParse,
            longMonthsParse: monthsParse,
            shortMonthsParse: monthsParse,
            weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
            weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
            weekdaysMin: 'do_lu_ma_mi_ju_vi_sá'.split('_'),
            weekdaysParseExact: true,
            longDateFormat: {
                LT: 'h:mm A',
                LTS: 'h:mm:ss A',
                L: 'MM/DD/YYYY',
                LL: 'D [de] MMMM [de] YYYY',
                LLL: 'D [de] MMMM [de] YYYY h:mm A',
                LLLL: 'dddd, D [de] MMMM [de] YYYY h:mm A',
                l: 'MM/DD',
                ll: 'D [de] MMMM',
                lll: 'D [de] MMMM h:mm A',
                llll: 'dddd, D [de] MMMM h:mm A',
            },
            calendar: {
                sameDay: function () {
                    return '[hoy a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                nextDay: function () {
                    return '[mañana a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                nextWeek: function () {
                    return 'dddd [a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                lastDay: function () {
                    return '[ayer a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                lastWeek: function () {
                    return '[el] dddd [pasado a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
                },
                sameElse: 'L',
            },
            relativeTime: {
                future: 'en %s',
                past: 'hace %s',
                s: 'unos segundos',
                ss: '%d segundos',
                m: 'un minuto',
                mm: '%d minutos',
                h: 'una hora',
                hh: '%d horas',
                d: 'un día',
                dd: '%d días',
                w: 'una semana',
                ww: '%d semanas',
                M: 'un mes',
                MM: '%d meses',
                y: 'un año',
                yy: '%d años',
            },
            dayOfMonthOrdinalParse: /\d{1,2}º/,
            ordinal: '%dº',
            week: {
                dow: 0, // Sunday is the first day of the week.
                doy: 6, // The week that contains Jan 6th is the first week of the year.
            },
        });
    });
};

const setupMoment = () => {
    initMoment(momentTZ);
};

export default setupMoment;
