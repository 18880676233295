import {produce} from 'immer';
import {List, Record} from 'immutable';

import {captureException} from 'web-app/services/sentry';

import {isPrimitive, type Primitive} from './shallow-diff';

/**
 * Taken from https://github.com/famly/nuclearfamly/blob/3a2971b1f09df613bdb4bacc74541222c204cfae/famlylib/src/main/scala/co/famly/logging/Request.scala#L59-L97
 * Keys/headers that match the below will have their values redacted. All the strings are uppercased before being
 * converted to a regex.
 */
const sensitive: Array<RegExp> = [
    /TOKEN/,
    /PASSWORD/,
    /SECRET/,
    // We only match "PIN" at the end of the string, since there's quite a few words with "PIN" in the middle
    // (like "PINNED"), but far fewer with it at the end.
    /PIN$/,
    /PINCODE/,
    /PINNUMBER/,
    // We only match "PW" at the end of a string, since there's quite a few words with "PW" in the middle
    // (like "upward"), but none with "PW" at the end.
    /PW$/,
];

// Tests a string against known sensitive cases
const isSensitive = (s: string): boolean => {
    const normalized = s.toUpperCase();
    return sensitive.some(regex => regex.test(normalized));
};

export const REDACTED = '[REDACTED]' as const;

/**
 * Function to redact sensitive values in objects. Only object keys are tested against known
 * "bad" values, such as "password", or "pincode".
 *
 * This function will recurse over arrays and object values.
 */
export const redactor = (data: Primitive | Array<any> | object) => {
    if (isPrimitive(data)) {
        return data;
    }
    if (data instanceof Array) {
        return data.map(redactor);
    }

    if (List.isList(data)) {
        return data.toJS().map(redactor);
    }

    const objectData = Record.isRecord(data) ? data.toJS() : data;

    if (typeof objectData === 'object') {
        try {
            return produce(objectData, draft => {
                for (const [key, value] of Object.entries(draft)) {
                    if (isSensitive(key)) {
                        draft[key] = REDACTED;
                    } else {
                        draft[key] = redactor(value);
                    }
                }
            });
        } catch (e) {
            captureException(e);

            return objectData;
        }
    }

    /**
     * Unknown territory - shouldn't happen.
     * typeof data is `never`.
     *
     * I've opted _not_ to include a log to Sentry as I want to keep this file as pure as possible.
     */
    return objectData;
};
