import {css} from 'styled-components';
import PropTypes from 'prop-types';

const breakpointValues = {
    mobile: 49,
    notMobile: 49.05,
    landscape: 1024,
    notLandscape: 1025,
    portrait: 60,
    notPortrait: 60.5,
    tablet: 64,
    notTablet: 64.05,
};
export const breakpointUnits = {
    mobile: 'em',
    notMobile: 'em',
    landscape: 'px',
    notLandscape: 'px',
    portrait: 'em',
    notPortrait: 'em',
    tablet: 'em',
    notTablet: 'em',
};
// TODO, this should be moved to the theme
export const breakpoints = {
    mobile: `${breakpointValues.mobile}${breakpointUnits.mobile}`,
    notMobile: `${breakpointValues.notMobile}${breakpointUnits.notMobile}`,
    landscape: `${breakpointValues.landscape}${breakpointUnits.landscape}`,
    notLandscape: `${breakpointValues.notLandscape}${breakpointUnits.notLandscape}`,
    portrait: `${breakpointValues.portrait}${breakpointUnits.portrait}`,
    notPortrait: `${breakpointValues.notPortrait}${breakpointUnits.notPortrait}`,
    tablet: `${breakpointValues.tablet}${breakpointUnits.tablet}`,
    notTablet: `${breakpointValues.notTablet}${breakpointUnits.notTablet}`,
};

const base = {
    atLeastLandscape: (...args) => css`
        @media (min-width: ${breakpoints.landscape}) {
            ${css(...args)}
        }
    `,
    mobile: (...args) => css`
        @media (max-width: ${breakpoints.mobile}) {
            ${css(...args)}
        }
    `,
    notMobile: (...args) => css`
        @media (min-width: ${breakpoints.notMobile}) {
            ${css(...args)}
        }
    `,
    portrait: (...args) => css`
        @media (max-width: ${breakpoints.portrait}) {
            ${css(...args)}
        }
    `,
    notPortrait: (...args) => css`
        @media (min-width: ${breakpoints.notPortrait}) {
            ${css(...args)}
        }
    `,
    tablet: (...args) => css`
        @media (max-width: ${breakpoints.tablet}) {
            ${css(...args)}
        }
    `,
    notTablet: (...args) => css`
        @media (min-width: ${breakpoints.notTablet}) {
            ${css(...args)}
        }
    `,
    landscape: (...args) => css`
        @media (max-width: ${breakpoints.landscape}) {
            ${css(...args)}
        }
    `,
    notLandscape: (...args) => css`
        @media (min-width: ${breakpoints.notLandscape}) {
            ${css(...args)}
        }
    `,
    print: (...args) => css`
        @media print {
            ${css(...args)}
        }
    `,

    mobileSideways: (...args) => css`
        @media screen and (max-height: ${breakpointValues.mobile -
            2}${breakpointUnits.mobile}) and (min-aspect-ratio: 4/3) {
            ${css(...args)}
        }
    `,

    nonStandardTablet: (...args) => css`
        @media (max-height: 560px) {
            ${css(...args)}
        }
    `,
};

/**
 * @deprecated Use app/styleguide/breakpoint-helpers.ts instead
 */
export const media = {
    ...base,
    multiple:
        (...args) =>
        props =>
            Object.keys(base).reduce((reduction, key) => {
                if (props[key]) {
                    return css`
                        ${reduction} ${base[key](...args)}
                    `;
                }
                return reduction;
            }, ''),
};

// Export all possible media keys as boolean proptypes.
// Meant to be used for prop keys when used the multiple type
export const mediaPropTypes = Object.keys(base).reduce((reduction, key) => ({...reduction, [key]: PropTypes.bool}), {});

/*
    Media query info: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/hover
    We've added this to be used when adding hover effects to components that we don't want to show up for touch devices.

    We've seen symptoms of clicks "bleeding through" resulting in stuck hover effects on underlying components on touch
    devices. By using this when added hover effects the intent is that they don't show up when using touch.
 */
export const addHover = CSS => {
    return css`
        @media (hover: hover) {
            ${CSS}
        }
    `;
};
