import {type MutationFunctionOptions, type FetchResult, type DataProxy} from '@apollo/client';
import {type DocumentNode} from 'graphql';

import {hasValue} from './typescript';
import {type NonEmptyArray} from './non-empty-array';

/**
 * Will return the name of the given Query, Mutation or Subscription component
 */
export const nameOfGraphQLOperation = (operation: DocumentNode) => {
    for (const definition of operation.definitions) {
        if (definition.kind === 'OperationDefinition') {
            if (hasValue(definition.name)) {
                return definition.name.value;
            }
        }
    }
    return null;
};

/**
 * Returns `string[]` containing the names of the given GraphQL operations.
 *
 * Example usage:
 * ```
 * useMutation(Queries.foo, {
 *   refetchQueries: namesOfGraphQLOperations(OtherQueries.bar, OtherQueries.wizbiz)
 * })
 * ```
 */
export const namesOfGraphQLOperations = (...args: NonEmptyArray<DocumentNode>) =>
    args.map(nameOfGraphQLOperation).filter(hasValue);

/**
 * Returns a function that can be used to check for specific errorcodes in a graphql error object
 *
 * Example usage:
 * ```
 * const isItThisSpecificError = checkForGraphQlErrorCode(12345789);
 *
 * if (isItThisSpecificError(errorFromGraphQl)) {
 *     // do something...
 * }
 * ```
 */
export const makeCheckForGraphQlErrorCode =
    (errorCode: number) =>
    (error: any): boolean => {
        if (!error) {
            return false;
        }

        // Check if there are graphql errors
        if (!Array.isArray(error.graphQLErrors)) {
            return false;
        }

        return error.graphQLErrors.some(error => {
            return error?.extensions?.errorCode === errorCode;
        });
    };

export type RefetchQueries = NonNullable<MutationFunctionOptions['refetchQueries']>;

// eslint-disable-next-line no-restricted-syntax
export enum EmptyCacheStrategy {
    SKIP_UPDATE = 'SKIP_UPDATE',
    ALLOW_OPTIONAL_CACHE = 'ALLOW_OPTIONAL_CACHE',
}

/**
 * Use this to update your cache after mutations!
 *
 * You have to pass two types to this generic function describing the cached query
 * you want to update <`CacheQuery`, `CacheQueryVariables`>.
 *
 * The updater will receive the current data in the cache in the shape of `CacheQuery`
 * and the result of the mutation which is inferred based on the mutation this is used in.
 * The `ReturnType` of `updater` has to match the shape of `CacheQuery`.
 *
 * @example
 * ```
 * myMutation({
 *   variables: {...},
 *   update: updateApolloCache<CacheQuery, CacheQueryVariables>()({
 *     type: 'query',
 *     node: MyCacheQuery,
 *     cacheVariables: {...},
 *     updater: (cachedData, newData) => ({
 *       result: [newData, ...cachedData]
 *     })
 *   })
 * })
 * ```
 */
/**
 * To more elegantly support both query and fragment updates we've introduced a new
 * interface that discriminates on `type: 'query' | 'fragment'` which can carry
 * conditionally required properties. To keep this change backwards compatible we've
 * moved the previous interface into a legacy type.
 */
export type UpdateApolloCache<CachedData, Variables, Result> =
    | LegacyProps<CachedData, Variables, Result>
    | ({cacheQuery?: never} & DiscriminatedProps<CachedData, Variables, Result>);

/**
 * @deprecated Use DiscriminatedProps instead
 */
type LegacyProps<CachedData, Variables, Result> =
    | {cacheQuery: DocumentNode} & InnerUpdateApolloCache<CachedData, Variables, Result>;

type DiscriminatedProps<CachedData, Variables, Result> = (
    | {type: 'query'; node: DocumentNode}
    | {type: 'fragment'; node: DocumentNode; id: FragmentId<CachedData>}
) &
    InnerUpdateApolloCache<CachedData, Variables, Result>;

/**
 * It's necessary to supply a fragment ID when updating fragments in the cache. This ID
 * has to follow a structure of `${__typename}:${objectId}`. Attempts to enforce this pattern
 * by using a string literal type and leveraging the existing types for `updateApolloCache`
 */
type FragmentId<CachedData> = CachedData extends {__typename?: string}
    ? `${NonNullable<CachedData['__typename']>}:${string}`
    : string;

/**
 * These properties have been extracted to
 */
type InnerUpdateApolloCache<CachedData, Variables, Result> = {
    cacheVariables?: CacheVariables<Result, Variables>;
} & (
    | {
          emptyCacheStrategy: EmptyCacheStrategy.ALLOW_OPTIONAL_CACHE;
          updater: (cachedData: CachedData | null, result: Result) => CachedData;
      }
    | {
          emptyCacheStrategy?: EmptyCacheStrategy.SKIP_UPDATE;
          updater: (cachedData: CachedData, result: Result) => CachedData;
      }
);

// Type guard to narrow the union in UpdateApolloCache
export const isDiscriminatedProps = <CachedData, Variables, Result>(
    props: UpdateApolloCache<CachedData, Variables, Result>,
): props is DiscriminatedProps<CachedData, Variables, Result> => 'type' in props && hasValue(props.type);

// This handles the deprecated interface and converts it to a type that can be discriminated
export const toDiscriminatedProps = <CachedData, Variables, Result>({
    cacheQuery,
    ...rest
}: LegacyProps<CachedData, Variables, Result>) =>
    ({
        type: 'query',
        node: cacheQuery,
        ...rest,
    } satisfies DiscriminatedProps<CachedData, Variables, Result>);

/**
 * While this overload is not necessary for having the types work, it allows us to
 * deprecate the old interface that doesn't use `type: 'query' | 'fragment'`
 */
interface CacheUpdaterFunction<CachedData, CacheVariables> {
    /**
     * @deprecated Use interface with `type: 'query' | 'fragment'` instead
     */
    <Result>(props: LegacyProps<CachedData, CacheVariables, Result>): CacheUpdater<Result>;
    <Result>(props: DiscriminatedProps<CachedData, CacheVariables, Result>): CacheUpdater<Result>;
}
export function updateApolloCache<CachedData, CacheVariables>(): CacheUpdaterFunction<CachedData, CacheVariables> {
    return function _typeInferredFunction<Result>(outerProps: UpdateApolloCache<CachedData, CacheVariables, Result>) {
        const props = isDiscriminatedProps(outerProps) ? outerProps : toDiscriminatedProps(outerProps);

        return function (cache: DataProxy, result: FetchResult<Result>) {
            if (!hasValue(result.data)) {
                return;
            }
            const variables = (() => {
                if (!hasValue(props.cacheVariables)) {
                    return undefined;
                }
                if (cacheVariablesIsTransform(props.cacheVariables)) {
                    return props.cacheVariables(result.data);
                }
                return props.cacheVariables;
            })();

            let cachedData: CachedData | null = null;
            try {
                cachedData =
                    props.type === 'query'
                        ? cache.readQuery<CachedData, CacheVariables>({
                              query: props.node,
                              variables,
                          })
                        : cache.readFragment<CachedData, CacheVariables>({
                              fragment: props.node,
                              id: props.id,
                              variables,
                          });
            } catch (e) {
                // No cache available
            }

            const writeCache = (data: CachedData) => {
                if (props.type === 'query') {
                    cache.writeQuery<CachedData, CacheVariables>({
                        query: props.node,
                        variables,
                        data,
                    });
                } else {
                    cache.writeFragment<CachedData, CacheVariables>({
                        fragment: props.node,
                        id: props.id,
                        variables,
                        data,
                    });
                }
            };

            switch (props.emptyCacheStrategy) {
                case EmptyCacheStrategy.ALLOW_OPTIONAL_CACHE:
                    writeCache(props.updater(cachedData, result.data));
                    break;
                default:
                    if (!cachedData) {
                        return;
                    }
                    writeCache(props.updater(cachedData, result.data));
            }
        };
    };
}

type TransformVariables<Result, Variables> = (result: Result) => Variables | undefined;
export type CacheVariables<Result, Variables> = TransformVariables<Result, Variables> | Variables;

const cacheVariablesIsTransform = <Result, Variables>(
    cacheVariables: CacheVariables<Result, Variables>,
): cacheVariables is TransformVariables<Result, Variables> => {
    return typeof cacheVariables === 'function';
};

type CacheUpdater<T> = (proxy: DataProxy, mutationResult: FetchResult<T>) => void;

/**
 * Use this to compose several calls to `updateApolloCache` together.
 * @param updates at least one call to `updateApolloCache`
 * @returns void
 *
 * @example
 * ```
 * myMutation({
 *   variables: {...},
 *   update: composeUpdates(
 *     updateApolloCache<CacheQuery, CacheQueryVariables>()({
 *       cacheQuery: MyCacheQuery,
 *       cacheVariables: {...},
 *       updater: (cachedData, newData) => ({
 *         result: [newData, ...cachedData]
 *       })
 *     }),
 *     updateApolloCache<CacheQuery2, CacheQueryVariables2>()({
 *       cacheQuery: MyCacheQuery2,
 *       cacheVariables: {...},
 *       updater: (cachedData, newData) => ({
 *         result: [newData, ...cachedData]
 *       })
 *     }),
 *   )
 * })
 * ```
 */
export const composeUpdates =
    <Result>(...updates: NonEmptyArray<CacheUpdater<Result>>) =>
    (cache: DataProxy, result: FetchResult<Result>) =>
        updates.forEach(updater => updater(cache, result));
