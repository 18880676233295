declare global {
    interface Number {
        roundTo(decimals: number): number;
    }
}

const addRoundTo = () => {
    if (Boolean(Number.prototype.roundTo)) {
        return;
    }

    Number.prototype.roundTo = function (this: string, decimals: number): number {
        return Number(Math.round(Number(`${this}e${decimals}`)) + 'e-' + decimals);
    };
};

export default addRoundTo;
