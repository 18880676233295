import moment from 'moment-timezone';
import i18next from 'i18next';
import numeral from 'numeral';

import LocalStorageService from 'web-app/services/local-storage';
import * as InContextTranslation from 'web-app/services/in-context-translation';
import SHARED_ENVIRONMENT_CONFIG from 'web-app/shared/environment';

const getOverriddenLocale = () => {
    return LocalStorageService.getFromLocalStorage('overriddenLocale');
};

const determineLocale = defaultLocale => {
    // If we're in translation mode, we always want
    // to use the esparanto language
    if (InContextTranslation.isTranslating()) {
        return 'eo_UY';
    }

    /*
    When we're in development mode, the selected language is 'en_US' and we're in the web-app,
    use the 'base.json' translation file.
    Added the local storage stuff so we can easily override this behavior.
    */
    const dontShowBaseTranslations = Boolean(LocalStorageService.getFromLocalStorage('dontShowBaseTranslations'));
    const i18nextLocale =
        SHARED_ENVIRONMENT_CONFIG.IS_DEVELOPMENT && !dontShowBaseTranslations && defaultLocale === 'en_US'
            ? 'en-JM'
            : defaultLocale;

    return i18nextLocale;
};

/**
 * Necessary as moment defines Nowegian as 'nb' (not 'no').
 *
 * If moment cannot properly resolve the provided locale,
 * it defaults to the locale last defined in app/setup/moment-setup.js.
 */
const resolveMomentLocale = ourLocale => {
    if (ourLocale.startsWith('no')) {
        return 'nb';
    } else {
        return ourLocale;
    }
};

export const setLocale = locale => {
    return new Promise((resolve, reject) => {
        const possibleOverriddenLocale = getOverriddenLocale();
        const chosenLocale = possibleOverriddenLocale || locale;

        moment.locale(resolveMomentLocale(chosenLocale));
        numeral.locale(chosenLocale);

        const i18nextLocale = possibleOverriddenLocale || determineLocale(locale);

        i18next.changeLanguage(i18nextLocale.replace('_', '-'), err => {
            if (err) {
                reject(err);
            }
            resolve(chosenLocale);
        });
        LocalStorageService.saveToLocalStorage('locale', chosenLocale);
    });
};

const hasSAsLastCharacter = name => name && name[name.length - 1] === 's';

const getUKOwners = name => {
    const hasS = hasSAsLastCharacter(name);

    if (hasS) {
        return `${name}'`;
    }

    return `${name}'s`;
};

const getDKOwners = name => {
    const hasS = hasSAsLastCharacter(name);

    if (hasS) {
        return `${name}'`;
    }

    return `${name}s`;
};

export const getOwnersFormName = nameObj => {
    switch (i18next.language) {
        case 'da-DK':
            return getDKOwners(nameObj.fullName);
        case 'en-GB':
        case 'en-US':
            return getUKOwners(nameObj.fullName);
        default:
            return nameObj.fullName;
    }
};

// Language name texts hardcoded.
export const languageOptions = [
    {
        label: 'English (UK)',
        value: 'en_GB',
    },
    {
        label: 'English (US)',
        value: 'en_US',
    },
    {
        label: 'Deutsch',
        value: 'de_DE',
    },
    {
        label: 'Schwizerdütsch',
        value: 'de_CH',
    },
    {
        label: 'Dansk',
        value: 'da_DK',
    },
    {
        label: 'Norsk',
        value: 'no_NO',
    },
    {
        label: 'Español',
        value: 'es_ES',
    },
    {
        label: 'Español (Latinoamérica)',
        value: 'es_US',
    },
];
